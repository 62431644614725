import Co2World from 'assets/co2world'
import i18next from 'core/i18n'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { ReactComponent as Sorted } from '../../assets/file-sorted.svg'
import { ReactComponent as StatisticCompressedFiles } from '../../assets/files-compressed.svg'
import { ReactComponent as StatisticSortedFiles } from '../../assets/files-sorted.svg'
import { ReactComponent as Hello } from '../../assets/hello.svg'
import { ReactComponent as StatisticSharedFiles } from '../../assets/icons/icon-share.svg'
import { ReactComponent as Laurier } from '../../assets/laurier.svg'
import { ReactComponent as IconTip } from '../../assets/tip.svg'
import Config from '../../core/config'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import Panel from '../shared/panel'
import Tooltip from '../shared/tooltip'
import ContentPage from './content-page'
import NewsBoard from './news/news-board'
import { TOTAL_TIPS } from './tips'
import { getEcoGain, getEcoGainAsKm } from './upload/co2-gain'
import WeTreasy from 'components/dataroom/we-treasy'

const Tip = ({ tip }: { tip: number }) => {
    const { t } = useTranslation()
    const [text, setText] = useState<string>()

    useEffect(() => {
        const text = t(`web_uploading_tip_${tip}`)

        const matchs = /^(?<star>.+!)\s.*/.exec(text)
        if (!matchs || !matchs.groups?.star) {
            setText(text)

            return
        }

        setText(text.replace(matchs.groups.star, `<span class="text-christine">${matchs.groups.star}</span>`))
    }, [tip])

    if (!text) {
        return null
    }

    return <p className="my-2" dangerouslySetInnerHTML={{ __html: text }} />
}

const Dashboard = observer(() => {
    const rootStore = useMst()
    const { user, admin, files, telecollecte, pages, brand } = rootStore
    const { meta } = files
    const { t } = useTranslation()

    const [welcomeMessageContent, setWelcomeMessageContent] = useState<string>('')
    const [newUser, setNewUser] = useState<boolean>(true)
    const [tip, setTip] = useState<number>(0)
    const [history, setHistory] = useState<string>()

    const loadWelcomeMessage = async (locale: string) => {
        let message: string

        await rootStore.getCmsPages(locale)
        const history = pages.get('history')
        if (history) {
            setHistory(history.text)
        }

        if (user.currentBrand) {
            message = await brand.loadWelcomeMessage(locale)
            message = message.replace(/\[BRAND\]/, user.currentBrand.name)
        } else if (user.partner && user.partner.uuid !== '') {
            await admin.loadPartnerWelcomeMessage(user.partner.uuid)
            message = admin.welcomeMessage.replace(/\[PARTNER\]/, user.partner.name)
        } else {
            const pageName = user.welcomePage
            const page = pages.get(pageName)
            if (page) {
                message = page.text
            }
        }
        setNewUser(user.isNewUser)

        const randomPart = /\[RANDOM\](?<parts>.*?)\[\/RANDOM\]/g.exec(message)
        if (randomPart?.groups?.parts) {
            const parts = randomPart.groups.parts.split('|')
            const randomPartIndex = Math.floor(Math.random() * parts.length)
            const randomPartText = parts[randomPartIndex]
            message = message.replace(randomPart[0], randomPartText)
        }
        setWelcomeMessageContent(message?.replaceAll(/\[USER\]/g, user.fullname) ?? '')
    }

    useEffect(() => {
        const syncConnector = async () => {
            await telecollecte.syncConnectors()
            await files.getFiles()
            await files.getConfig()
        }

        syncConnector()
    }, [files])

    useEffect(() => {
        loadWelcomeMessage(i18next.language)

        const totalTips = TOTAL_TIPS
        const randomTip = Math.floor(Math.random() * totalTips)
        setTip(randomTip)
    }, [i18next.language])

    return (
        <ContentPage title={t('web_dataroom_dashboard')}>
            <div className="grid grid-cols-12 gap-2 xl:gap-4">
                <div className="welcome col-span-12 flex flex-col space-y-8 xl:col-span-8">
                    {welcomeMessageContent && (
                        <Panel className="welcome" innerClassName="relative bg-white">
                            <div className="flex w-full flex-row">
                                {newUser && <Hello className="mx-8 -mt-16 hidden h-[250px] xl:block" />}
                                <div className="mt-4 w-full md:ml-2 md:mt-0">
                                    <ReactMarkdown
                                        className="prose w-full max-w-full"
                                        components={{
                                            a: ({ node, href, children, ...props }) => {
                                                if (href.startsWith('http') && !href.startsWith(Config.app.WEBURL)) {
                                                    return (
                                                        <a
                                                            href={href}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            {...props}
                                                        >
                                                            {children}
                                                        </a>
                                                    )
                                                }

                                                return (
                                                    <a href={href} {...props}>
                                                        {children}
                                                    </a>
                                                )
                                            },
                                        }}
                                    >
                                        {welcomeMessageContent}
                                    </ReactMarkdown>
                                </div>
                            </div>
                            {!newUser && <Laurier className="absolute -bottom-4 -right-2 h-[117px]" />}
                        </Panel>
                    )}

                    <WeTreasy />

                    <NewsBoard />

                    {history && (
                        <Panel className="" innerClassName="relative bg-white">
                            <ReactMarkdown className="prose w-full max-w-full">{history}</ReactMarkdown>
                        </Panel>
                    )}
                </div>

                <div className="col-span-12 mt-6 flex flex-row space-x-4 font-roboto text-sm xl:col-span-4 xl:mt-0 xl:flex-col xl:space-x-0 xl:space-y-8">
                    <Panel noSize className="w-1/2 xl:w-full">
                        <div className="w-full">
                            <Sorted className="m-auto -mt-8 h-[124px]" />
                        </div>
                        <div className="p-4">
                            <h3 className="mb-6 text-center text-2xl font-bold">
                                {t('web_dashboard_statistic_title')}
                            </h3>
                            <Tooltip tooltip={t('web_tooltips_statistics_sorted_files')}>
                                <div className="flex">
                                    <div className="flex h-12 w-12 items-center justify-center rounded-50 bg-tree-poppy">
                                        <StatisticSortedFiles className="fill-current text-white" />
                                    </div>
                                    <div className="ml-4 flex flex-col">
                                        <span className="text-2xl font-bold">{meta.sorted}%</span>
                                        <span className="-mt-2 text-sm">
                                            {t('web_dashboard_statistics_sorted_files')}
                                        </span>
                                    </div>
                                </div>
                            </Tooltip>
                            <p className="mb-10 mt-2">{t('web_dashboard_statistics_sorted_files_desc')}</p>

                            <Tooltip tooltip={t('web_tooltips_statistics_zipped_files')}>
                                <div className="flex">
                                    <div className="flex h-12 w-12 items-center justify-center rounded-50 bg-plum">
                                        <StatisticCompressedFiles className="fill-current text-white" />
                                    </div>
                                    <div className="ml-4 flex flex-col">
                                        <span className="text-2xl font-bold">{meta.compressed}%</span>
                                        <span className="-mt-2 text-sm">
                                            {t('web_dashboard_statistics_compressed_files')}
                                        </span>
                                    </div>
                                </div>
                            </Tooltip>
                            <p className="mb-10 mt-2">{t('web_dashboard_statistics_compressed_files_desc')}</p>

                            <div className="flex">
                                <div className="flex h-12 w-12 items-center justify-center rounded-50 bg-selective-yellow">
                                    <StatisticSharedFiles className="fill-current text-white" />
                                </div>
                                <div className="ml-4 flex flex-col">
                                    <span className="text-2xl font-bold">{meta.sharedCount}</span>
                                    <span className="-mt-2 text-sm">
                                        {t('web_dashboard_statistics_shared_files', { count: meta.sharedCount })}
                                    </span>
                                </div>
                            </div>
                            <p className="mb-10 mt-2">{t('web_dashboard_statistics_shared_files_desc')}</p>
                        </div>
                    </Panel>

                    <Panel noSize>
                        <h2 className="text-center text-2xl font-bold">{t('web_dashboard_tip_title')}</h2>
                        <div className="flex flex-row items-center justify-center space-x-2">
                            <div>
                                <IconTip className="h-12 w-12" />
                            </div>
                            <div className="flex flex-col">
                                <Tip tip={tip} />
                            </div>
                        </div>
                    </Panel>

                    <Panel noSize>
                        <div className="flex w-full gap-2">
                            <Co2World className="w-32 shrink-0" />
                            <div className="flex flex-col items-start">
                                <h2 className="text-center text-2xl font-bold text-turquoise">
                                    {getEcoGain(t, files.meta.co2gain)}
                                </h2>
                                <h2 className="text-center text-sm text-inner-space">
                                    {t('web_dashboard_co2_title', { count: files.meta.co2gain })}
                                </h2>
                                <p
                                    className="mt-2"
                                    dangerouslySetInnerHTML={{ __html: getEcoGainAsKm(t, files.meta.co2gain) }}
                                />
                            </div>
                        </div>
                    </Panel>
                </div>

                {/* <Panel noSize className="col-span-6 xl:col-span-4 xl:col-start-9">
                    <div className="flex flex-col items-center">
                        <TodoLogo className="my-8" />
                        <h3 className="text-lg">{t('web_dashboard_todo_title')}</h3>
                        <p className="text-sm my-2">
                            <Trans i18nKey="web_dashboard_todo_desc_1">web_dashboard_todo_desc_1</Trans>
                        </p>
                        <p className="text-sm my-2">
                            <Trans i18nKey="web_dashboard_todo_desc_2">web_dashboard_todo_desc_2</Trans>
                        </p>
                        <p className="text-sm my-2">
                            <Trans i18nKey="web_dashboard_todo_desc_3">web_dashboard_todo_desc_3</Trans>
                        </p>
                        <a className="btn" href="mailto:assistance@treasy.fr">
                            {t('web_dashboard_todo_contact')}
                        </a>
                    </div>
                </Panel> */}

                {Config.env === 'localhost ' && (
                    <>
                        <Panel className="mb-4">
                            <h2>Telecollecte</h2>
                            <div className="m-2">
                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.addConnectorType('bank')
                                        const url = telecollecte.addConnectorUrl
                                        window.location.href = url
                                    }}
                                >
                                    add bank Connector
                                </button>

                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.addConnectorType('document')
                                        const url = telecollecte.addConnectorUrl
                                        window.location.href = url
                                    }}
                                >
                                    add provider Connector
                                </button>

                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.manageConnector()
                                        const url = telecollecte.addConnectorUrl
                                        window.location.href = url
                                    }}
                                >
                                    manageConnector
                                </button>

                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.listConnectors()
                                    }}
                                >
                                    listConnectors
                                </button>

                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.syncConnectors()
                                    }}
                                >
                                    syncConnectors
                                </button>

                                <button
                                    className="btn mr-4"
                                    onClick={async () => {
                                        await telecollecte.getDocuments()
                                    }}
                                >
                                    getDocuments
                                </button>
                            </div>
                        </Panel>

                        <Panel className="mb-4">
                            <h2>Design</h2>
                            <div className="m-2">
                                <p>Boutons :</p>
                                <p className="my-4">
                                    <button className="btn disabled mx-2">Disabled</button>
                                    <button className="btn mx-2">Idle</button>
                                    <button className="btn hover mx-2">Hover</button>
                                    <button className="btn active mx-2">Pressed</button>
                                </p>
                                <p className="my-4">
                                    <button className="btn white disabled mx-2">Disabled</button>
                                    <button className="btn white mx-2">Idle</button>
                                    <button className="btn white hover mx-2">Hover</button>
                                    <button className="btn white active mx-2">Pressed</button>
                                </p>
                            </div>
                        </Panel>

                        <Panel className="mb-4">
                            <div className="m-2">
                                <p>Notifications :</p>
                                <p>
                                    <button
                                        className="btn mx-2"
                                        onClick={async () => {
                                            toast('success', 'Ca a marché')
                                        }}
                                    >
                                        Succès
                                    </button>
                                    <button
                                        className="btn mx-2"
                                        onClick={async () => {
                                            toast('info', 'Information')
                                        }}
                                    >
                                        Info
                                    </button>
                                    <button
                                        className="btn mx-2"
                                        onClick={async () => {
                                            toast('warning', 'Attention !!!')
                                        }}
                                    >
                                        Warning
                                    </button>
                                    <button
                                        className="btn mx-2"
                                        onClick={async () => {
                                            toast('error', 'Oops')
                                        }}
                                    >
                                        Erreur
                                    </button>
                                </p>
                            </div>
                        </Panel>

                        <Panel className="mb-4">
                            <div className="m-2">
                                <p>Files :</p>
                                <p>
                                    <button
                                        className="btn mx-2"
                                        onClick={async () => {
                                            await files.getSpaceFilesCount()
                                        }}
                                    >
                                        files.getSpaceFilesCount
                                    </button>
                                </p>
                            </div>
                        </Panel>
                    </>
                )}
            </div>
        </ContentPage>
    )
})

export default Dashboard
