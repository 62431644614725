import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import { del, get, post, put } from '../core/services/http-service'
import { _InformationMessage, InformationMessage } from './informationMessage/information-message'
import { RootInstance } from './store'

export const _InformationMessageStore = types
    .model('InformationMessageStore', {
        informationMessages: types.array(_InformationMessage),
        editingInformationMessage: types.optional(_InformationMessage, {}),
    })
    .volatile<{ loaded: boolean }>(() => ({
        loaded: false,
    }))
    .actions(self => ({
        replace: ({ informationMessages }) => {
            self.informationMessages = informationMessages.sort((a, b) => b.createdAt - a.createdAt)
        },
        setLoaded: loaded => {
            self.loaded = loaded
        },
        removedInformationMessage(id) {
            const removedInformationMessage = self.informationMessages.filter(informationMessage => {
                informationMessage.uuid = id
            })[0]
            self.informationMessages.remove(removedInformationMessage)
        },
        append(informationMessage) {
            self.informationMessages.push(informationMessage)
        },
        assignInformationMessage({ informationMessage }) {
            self.editingInformationMessage = informationMessage
        },
    }))
    .actions(self => ({
        load: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<void, JsonReturn>('/v1/web/information-messages')

                const {
                    data: { informationMessages },
                } = data
                self.setLoaded(true)
                self.replace({ informationMessages })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        informationMessageCreate: flow(function* (
            title: string,
            bodyMessage: string,
            dismissible: boolean,
            startDate: Date,
            endDate: Date,
            color: string,
            partnerUuid: string | null,
            brandUuid: string | null,
            fromPartner: string = undefined,
            users: string[] = undefined,
            tags: string[] = undefined,
            franchiseUuids: string[] = undefined
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    title,
                    bodyMessage,
                    dismissible,
                    startDate: startDate ? startDate.toISOString() : null,
                    endDate: endDate ? endDate.toISOString() : null,
                    color,
                    partnerUuid,
                    brandUuid,
                    fromPartner,
                    users,
                    tags,
                    franchiseUuids,
                }
                const data = yield post<typeof postData, InformationMessage>('/v1/bo/information-messages', postData)

                self.append(data.data)

                toast('success', 'web_menu_admin_information_message_created')

                return data.data
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        dismissMessage: flow(function* (messageID) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = { informationMessageId: messageID }
                yield post<typeof data, void>('/v1/web/information-messages/dismiss', data)
            } catch (err) {
                root.error.prepare(err)
            }
        }),
        loadInformationMessage: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: InformationMessage }>(`/v1/bo/information-messages/${id}`)
                const informationMessage = data.data
                self.assignInformationMessage({ informationMessage })
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        informationMessageUpdate: async function (
            id: string,
            title: string,
            dismissible: boolean,
            startDate: Date,
            endDate: Date,
            bodyMessage: string,
            color: string,
            partnerUuid: string | null,
            brandUuid: string | null,
            users: string[] = undefined,
            tags: string[] = undefined,
            franchiseUuids: string[] = undefined
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                title,
                bodyMessage,
                color,
                partnerUuid,
                brandUuid,
                dismissible,
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null,
                users,
                tags,
                franchiseUuids,
            }

            try {
                await put<typeof jsonData, void>(`/v1/bo/information-messages/${id}`, jsonData)

                toast('success', 'web_admin_information_message_updated')
            } catch (error) {
                root.error.prepare(error)
            }
        },

        informationMessageDelete: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()
            try {
                yield del<void>(`/v1/bo/information-messages/${id}`)
                self.removedInformationMessage(id)
                toast('success', 'web_menu_admin_information_message_deleted')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        append(informationMessage) {
            self.informationMessages.push(informationMessage)
        },
    }))

export interface InformationMessageStore extends Instance<typeof _InformationMessageStore> {}
