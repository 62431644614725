import { Input, selectStyles } from 'components/shared/select-styles'
import { useEffect, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import type { FormatOptionLabelMeta, GroupBase, MultiValue, Options, OptionsOrGroups } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import type { Accessors } from 'react-select/dist/declarations/src/useCreatable'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import _ from 'lodash'

type Option = { value?: string; label: string; options?: Option[] }

export function UsersDropdown({
    selectedPeople,
    setSelectedPeople,
    space,
}: {
    selectedPeople: MultiValue<Option>
    setSelectedPeople: Dispatch<SetStateAction<MultiValue<Option>>>
    space?: Space
}) {
    const { t } = useTranslation()
    const { user } = useMst()

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSelectedPeople([
            ...selectedPeople,
            ...(space?.invitations ?? []).map(email => ({ value: email, label: email })),
        ])
    }, [space])

    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    async function loadOptions(
        inputValue: string,
        callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void
    ) {
        const { trustedUsers, trustedUsersPerFranchise, trustedUsersPerBrand, otherUsers } = await user.findTrustedUser(
            inputValue,
            { getPendingInvitations: false }
        )

        const users = [...trustedUsers]

        for (const franchise of trustedUsersPerFranchise) {
            users.push(...Object.values(franchise))
        }

        for (const brand of trustedUsersPerBrand) {
            users.push(...Object.values(brand))
        }

        const options: Option[] = []

        if (users.length > 0) {
            options.push({
                label: t('web_collaboration_trusted_users'),
                options: users
                    .flat()
                    .filter(({ trustedUser }) => !selectedPeople.find(({ value }) => value === trustedUser.uuid))
                    .map(({ accepted, trustedUser, userType }) => {
                        return {
                            value: trustedUser.uuid,
                            label: `${trustedUser.fullname} (${trustedUser.email})`,
                        }
                    }),
            })
        }

        if (otherUsers.length > 0) {
            options.push({
                label: t('web_collaboration_other_users'),
                options: otherUsers.map(user => ({
                    value: user.uuid,
                    label: `${user.fullname} (${user.email})`,
                })),
            })
        }

        callback(options)
    }

    const _loadOptions = _.debounce(
        (inputValue: string, callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void) => {
            loadOptions(inputValue, callback)
        },
        800
    )

    return (
        <AsyncCreatableSelect
            isValidNewOption={(
                inputValue: string,
                value: Options<Option>,
                options: OptionsOrGroups<Option, GroupBase<Option>>,
                accessors: Accessors<Option>
            ) =>
                options.length === 0 &&
                emailRegex.test(inputValue) &&
                !selectedPeople.map(({ value }) => value.toLowerCase()).includes(inputValue.toLowerCase())
            }
            formatCreateLabel={(inputValue: string) => {
                return t('web_collaboration_invite_email', { email: inputValue })
            }}
            formatOptionLabel={(data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => {
                return formatOptionLabelMeta.context === 'menu' || !emailRegex.test(data.value)
                    ? data.label
                    : t('web_collaboration_invite_email_selected', { email: data.label })
            }}
            styles={selectStyles}
            components={{ Input }}
            className="w-full"
            value={selectedPeople}
            isClearable={true}
            isMulti
            defaultOptions
            noOptionsMessage={() => t('web_collaboration_no_options')}
            placeholder={t('web_collaboration_create_add_people')}
            loadOptions={_loadOptions}
            onChange={value => setSelectedPeople(value)}
        />
    )
}
