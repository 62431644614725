import { type Instance, applySnapshot, types } from 'mobx-state-tree'

export const _StripePrice = types
    .model('StripePrice', {
        active: true,
        id: '',
        nickname: types.optional(types.string, '', [null, undefined]),
        currency: '',
        product: '',
        unit_amount: 0,
    })
    .views(self => ({
        get friendlyAdminName() {
            return `${self.nickname !== '' ? self.nickname : self.id} (${self.unit_amount / 100}${self.currency})`
        },
    }))
export interface StripePrice extends Instance<typeof _StripePrice> {}

export const _StripeProduct = types
    .model('StripeProduct', {
        active: true,
        id: '',
        name: '',
        description: types.optional(types.string, '', [null, undefined]),
    })
    .views(self => ({
        get friendlyAdminName() {
            return `${self.name}`
        },
    }))
export interface StripeProduct extends Instance<typeof _StripeProduct> {}

export const _Pricing = types
    .model('Pricing', {
        uuid: '',
        name: '',
        publicName: '',
        description: '',
        longDescription: types.optional(types.string, '', [null, undefined]),
        price: types.optional(types.string, '', [null, undefined]),
        product: types.optional(types.string, '', [null, undefined]),
        free: false,
        manual: false,
        isDefault: false,
        treasy: false,
        tribe: types.optional(types.integer, 0, [null, undefined]),
        repeatable: false,
        storage: 0,
        storageMessage: types.optional(types.string, '', [null, undefined]),
        storageCta: types.optional(types.number, 0, [null, undefined]),
        connectors: 0,
        connectorsMessage: types.optional(types.string, '', [null, undefined]),
        connectorsCta: types.optional(types.number, 0, [null, undefined]),
        shareFiles: 0,
        shareFilesMessage: types.optional(types.string, '', [null, undefined]),
        shareFilesCta: types.optional(types.number, 0, [null, undefined]),
        transmission: 0,
        transmissionMessage: types.optional(types.string, '', [null, undefined]),
        transmissionCta: types.optional(types.number, 0, [null, undefined]),
        _duration: '',
        _currency: '€',
        _unit_amount: 0,
        usedBy: 0,
        upgrade: types.optional(types.model({ name: types.string, uuid: types.string }), { name: '', uuid: '' }, [
            null,
            undefined,
        ]),
    })
    .views(self => ({
        get isTribe(): boolean {
            return self.tribe > 0
        },
        get duration(): { length: number; period: 'days' | 'months' | 'years' } {
            return JSON.parse(self._duration)
        },
        get displayCurrency(): string {
            switch (self._currency) {
                case 'eur':
                    return '€'
                default:
                    return self._currency
            }
        },
        get displayPrice() {
            return `${self._unit_amount / 100}${this.displayCurrency}`
        },
    }))
    .actions(self => ({
        reset: () => {
            applySnapshot(self, {})
        },

        refresh: pricing => {
            applySnapshot(self, pricing)
        },
    }))

export interface Pricing extends Instance<typeof _Pricing> {}
