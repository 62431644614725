import { ReactComponent as CtaArrowTop } from 'assets/file-transfer/cta-arrow-top.svg'
import Cta from 'assets/file-transfer/backgrounds/collaboration.jpg'
import Panel from 'components/shared/panel'
import { useTranslation } from 'react-i18next'

export default function WeTreasy() {
    const { t } = useTranslation()

    const url =
        !process.env.REACT_APP_WE_TREASY_WEB_HOST || process.env.REACT_APP_WE_TREASY_WEB_HOST === ''
            ? '/we-treasy'
            : process.env.REACT_APP_WE_TREASY_WEB_HOST

    return (
        <Panel className="welcome" innerClassName="relative bg-white">
            <div className="h-[300px] w-full bg-cover bg-center" style={{ background: `url(${Cta})` }} />
            <div className="justify-between flex items-center w-full pt-6 pb-3">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('web_file_transfer_dashboard', {
                            url: `<a href="${url}" target="_blank" class="underline" rel="noreferrer">${url}</a>`,
                        }),
                    }}
                />

                <a href={url} target="_blank" className="btn relative whitespace-nowrap" rel="noreferrer">
                    <div className="absolute right-8 bottom-4 hidden lg:block">
                        <CtaArrowTop className="rotate-180" />
                    </div>
                    {t('web_file_transfer_cta_discover_short')}
                </a>
            </div>
        </Panel>
    )
}
