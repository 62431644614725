import { faCaretRight, faExclamationTriangle, faFolderOpen, faPen, faUserPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'
import Tooltip from 'components/shared/tooltip'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import type { SpaceDirectory } from 'stores/files/directory'
import type { SpaceFile } from 'stores/files/file'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import { twMerge } from 'tailwind-merge'
import Editable from '../../shared/editable'
import Star from '../star'
import DirectoryIcon from './directory-icon'
import { File, baseClassNames, checkboxClassNames } from './file'
import { Line } from './line'
import { Loader } from './loader'
import { type FileOrDirectoryProps, padding } from './props'
import { Row } from './row'
import { checkboxColor } from './space'

interface DirectoryProps extends FileOrDirectoryProps {
    directory: SpaceDirectory
    space: Space
    cmp: (a: SpaceFile | SpaceDirectory, b: SpaceFile | SpaceDirectory) => number
    onSelect: (object: SpaceFile | SpaceDirectory | Space) => void
    onDeselect: (object: SpaceFile | SpaceDirectory | Space) => void
    selectedFiles: SpaceFile[] | SpaceDirectory[]
    highlightedFile?: string
    highlightedDirectory?: string
    openingIds: string[]
    color?: string
    onRenameSuggestionClicked: (file: SpaceFile) => void
    canOfferSuggestion: boolean
    onOpenChecklist: (directory: SpaceDirectory) => void
    onClientEdit?: (directory: SpaceDirectory) => void
}

export const Directory = observer(
    ({
        directory,
        space,
        level,
        cmp,
        selected,
        canChangeSelection,
        color = '#F15F12',
        onSelect,
        onDeselect,
        onRenameSuggestionClicked,
        onSetFileDocumentTypeClicked,
        selectedFiles,
        highlightedFile,
        highlightedDirectory,
        openingIds,
        canOfferSuggestion,
        onOpenChecklist,
        isRefreshing,
        onClientEdit,
    }: DirectoryProps) => {
        const { t } = useTranslation()
        const { files, user } = useMst()

        const [loading, setLoading] = useState<boolean>(false)

        const [editName, setEditName] = useState<boolean>(false)
        const [name, setName] = useState<string>(directory.name)
        const [isOver, setOver] = useState<boolean>(false)
        const [checked, setChecked] = useState<boolean>(false)
        const [disabled, setDisabled] = useState<boolean>(false)

        const changeSelection = (newValue: boolean) => {
            newValue ? onSelect(directory) : onDeselect(directory)
            setChecked(newValue)
        }

        const toggleChecked = () => {
            const newValue = !checked
            changeSelection(newValue)
        }
        useEffect(() => {
            setChecked(selected)
            setDisabled(!canChangeSelection || isRefreshing)
        }, [selected, canChangeSelection, isRefreshing])

        const nameRef = useRef<HTMLInputElement>(null)

        useEffect(() => {
            if (openingIds.includes(directory.uuid)) {
                directory.open()
            }
        }, [directory.uuid, openingIds])

        useEffect(() => {
            editName && nameRef.current && nameRef.current.focus()
        }, [editName, nameRef])

        const onSave = async (name: string) => {
            await directory.rename(name)
            setName(name)
            setEditName(false)
        }

        const collator = new Intl.Collator(user.locale, { numeric: true })

        useEffect(() => {
            if (highlightedDirectory === directory.uuid && !highlightedFile) {
                scroller.scrollTo(highlightedDirectory, {
                    duration: 100,
                    delay: 100,
                    smooth: true,
                    offset: 50,
                })
            }
        }, [highlightedDirectory, highlightedFile])

        const toggleOpen = async () => {
            if (directory.isOpen) {
                directory.close()
            } else {
                directory.open()
                setLoading(true)
                await directory.refresh()
                setLoading(false)
            }
        }

        const load = async (directory: SpaceDirectory) => {
            setLoading(true)
            await directory.refresh()
            files.clearForceRefresh(directory.uuid)
            setLoading(false)
        }
        useEffect(() => {
            if ((directory.isOpen && !directory.isLoaded) || files.shouldForceRefresh(directory.uuid)) {
                load(directory)
            }
        }, [files.forceRefresh, directory])

        return (
            <>
                <Line fileOrDirectory={directory}>
                    {user.canManagePersonalData && (
                        <Row
                            highlight={!highlightedFile && highlightedDirectory === directory.uuid}
                            dropType="directory"
                            dropUuid={directory.uuid}
                            className={clsx(
                                'group flex items-center justify-center border-l-2 border-christine',
                                checkboxColor(isOver, checked, canChangeSelection),
                                baseClassNames
                            )}
                            onMouseEnter={() => setOver(true)}
                            onMouseLeave={() => setOver(false)}
                        >
                            <input
                                type="checkbox"
                                checked={checked}
                                disabled={disabled}
                                onChange={() => toggleChecked()}
                                className={checkboxClassNames}
                            />
                        </Row>
                    )}
                    <Row
                        highlight={!highlightedFile && highlightedDirectory === directory.uuid}
                        style={{
                            paddingLeft: `${padding(level)}rem`,
                            gridColumn: 'span 15 / span 15',
                        }}
                        className={twMerge(baseClassNames.replace(/px-2/, ''), 'py-1 pr-1')}
                        dropType="directory"
                        dropUuid={directory.uuid}
                    >
                        <Element name={directory.uuid} className="flex items-center space-x-2">
                            <FontAwesomeIcon
                                icon={faCaretRight}
                                className="cursor-pointer fill-current text-inner-space"
                                rotation={directory.isOpen ? 90 : undefined}
                                size="xs"
                                onClick={() => toggleOpen()}
                            />
                            {directory.jsonOptions.brandDirectory ? (
                                <FontAwesomeIcon
                                    icon={faFolderOpen}
                                    className="h-6 w-6 cursor-pointer fill-current"
                                    style={{ color: directory.jsonOptions.color ?? color }}
                                    onClick={() => toggleOpen()}
                                />
                            ) : (
                                <DirectoryIcon
                                    color={directory.jsonOptions.color ?? color}
                                    onClick={() => toggleOpen()}
                                    connectorUuid={directory.connectorUuid}
                                    userDirectory={directory.jsonOptions.userDirectory}
                                />
                            )}
                            <Editable
                                isEditing={editName}
                                label={name}
                                onSave={name => onSave(name)}
                                onCancel={() => setEditName(false)}
                            >
                                <div className="flex w-full items-center justify-between space-x-2">
                                    <span
                                        className={clsx('grow cursor-pointer truncate font-bold', {})}
                                        onClick={() => toggleOpen()}
                                    >
                                        {directory.jsonOptions.brandDirectory ? name.toUpperCase() : name}
                                    </span>
                                    {directory.jsonOptions.userDirectory && (
                                        <>
                                            {directory.expirationDate && directory.expirationDays < 30 && (
                                                <div className="flex items-center justify-center gap-2">
                                                    <FontAwesomeIcon
                                                        icon={faExclamationTriangle}
                                                        className="text-red-500"
                                                    />
                                                    <span className="text-sm">
                                                        {t(
                                                            directory.expirationDays > 0
                                                                ? 'web_checklist_client_expire'
                                                                : 'web_checklist_client_expired',
                                                            {
                                                                expire: directory.expirationDate.toRelative(),
                                                            }
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {directory.checklist && directory.checklist.uuid !== '' && (
                                                <div
                                                    className="flex cursor-pointer items-center justify-center gap-2"
                                                    onClick={() => onOpenChecklist(directory)}
                                                >
                                                    <Tooltip
                                                        tooltip={t('web_checklist_percent', {
                                                            percent: directory.checklistPercentage,
                                                        })}
                                                    >
                                                        <div style={{ color }}>
                                                            <Star
                                                                className="h-4 w-4 shrink-0 grow-0"
                                                                percent={directory.checklistPercentage}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                    <span className="text-sm font-bold">{t('web_see_checklist')}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {directory.isEditAllowed() && directory.isEditableBy(user) && (
                                        <Tooltip tooltip={t('web_directory_rename')}>
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                fixedWidth
                                                size="sm"
                                                className="shrink-0 grow-0 cursor-pointer fill-current text-pale-sky"
                                                onClick={() => setEditName(true)}
                                            />
                                        </Tooltip>
                                    )}
                                    {directory.jsonOptions.userDirectory &&
                                        directory.isEditAllowed() &&
                                        directory.isEditableBy(user) && (
                                            <Tooltip tooltip={t('web_client_edit')}>
                                                <FontAwesomeIcon
                                                    icon={faUserPen}
                                                    fixedWidth
                                                    size="sm"
                                                    className="shrink-0 grow-0 cursor-pointer fill-current text-pale-sky"
                                                    onClick={() => onClientEdit(directory)}
                                                />
                                            </Tooltip>
                                        )}
                                </div>
                            </Editable>
                        </Element>
                    </Row>
                    <Row
                        highlight={!highlightedFile && highlightedDirectory === directory.uuid}
                        dropType="directory"
                        dropUuid={directory.uuid}
                        className={clsx('col-span-2', baseClassNames)}
                    />
                    <Row
                        highlight={!highlightedFile && highlightedDirectory === directory.uuid}
                        dropType="directory"
                        dropUuid={directory.uuid}
                        className={clsx('col-span-2 text-center', baseClassNames)}
                    >
                        <span className="hidden xl:block">{t('web_spaceroom_directory')}</span>
                        <span className="xl:hidden">{t('web_spaceroom_directory_short')}</span>
                    </Row>
                    <Row
                        highlight={!highlightedFile && highlightedDirectory === directory.uuid}
                        dropType="directory"
                        dropUuid={directory.uuid}
                        className={clsx('col-span-2', baseClassNames, {
                            'bg-christine/10': !highlightedFile && highlightedDirectory === directory.uuid,
                        })}
                    />
                </Line>
                {loading && <Loader />}
                {directory.isOpen && (
                    <>
                        {[...values<SpaceDirectory>(directory.directories)]
                            .sort((a: SpaceDirectory, b: SpaceDirectory) => {
                                return collator.compare(a.displayName, b.displayName)
                            })
                            .map(child => (
                                <Directory
                                    isRefreshing={isRefreshing}
                                    key={child.uuid}
                                    directory={child}
                                    space={space}
                                    level={level + 1}
                                    color={directory.jsonOptions.color ?? color}
                                    cmp={cmp}
                                    selected={selectedFiles.find(f => f.uuid === child.uuid) !== undefined}
                                    canChangeSelection={!selected && canChangeSelection}
                                    onSelect={onSelect}
                                    onDeselect={onDeselect}
                                    selectedFiles={selectedFiles}
                                    highlightedFile={highlightedFile}
                                    highlightedDirectory={highlightedDirectory}
                                    openingIds={openingIds}
                                    onRenameSuggestionClicked={onRenameSuggestionClicked}
                                    onSetFileDocumentTypeClicked={onSetFileDocumentTypeClicked}
                                    canOfferSuggestion={canOfferSuggestion}
                                    onOpenChecklist={onOpenChecklist}
                                    onClientEdit={onClientEdit}
                                />
                            ))}
                        {values<SpaceFile>(directory.files)
                            .slice()
                            .sort(cmp)
                            .map(child => (
                                <File
                                    isRefreshing={isRefreshing}
                                    key={child.uuid}
                                    space={space}
                                    directory={directory}
                                    file={child}
                                    level={level + 1}
                                    selected={selectedFiles.find(f => f.uuid === child.uuid) !== undefined}
                                    canChangeSelection={!selected && canChangeSelection}
                                    onSelect={onSelect}
                                    onDeselect={onDeselect}
                                    highlightedFile={highlightedFile}
                                    onRenameSuggestionClicked={onRenameSuggestionClicked}
                                    onSetFileDocumentTypeClicked={onSetFileDocumentTypeClicked}
                                    canOfferSuggestion={canOfferSuggestion}
                                />
                            ))}
                    </>
                )}
            </>
        )
    }
)
