import localeFr from 'air-datepicker/locale/fr'
import { clsx } from 'clsx'
import DatePicker from 'components/admin/date-picker'
import { FormProps, optionValueSchema } from 'components/admin/forms'
import Label from 'components/admin/label'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { useMst } from '../../../stores/store'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { get } from 'core/services/http-service'
import { z } from 'zod'

const schema = z.object({
    title: z.string().min(1),
    bodyMessage: z.string().min(1),
    color: z.string(),
    dismissible: z.boolean().nullable().default(false),
    startDate: z.date(),
    endDate: z.date(),
    users: z.array(optionValueSchema).nullish(),
    tags: z.array(optionValueSchema).nullish(),
    franchiseUuids: z.array(optionValueSchema).nullish(),
})
export type Schema = z.infer<typeof schema>

const Form = ({ brandUuid, onSubmit, values }: FormProps<Schema> & { brandUuid: string }) => {
    const { admin, files, brand } = useMst()
    const { t } = useTranslation()

    const sends: { value: 'ALL' | 'USER' | 'TAG' | 'FRANCHISE'; label: string }[] = [
        { value: 'ALL', label: t('web_partner_send_message_all') },
        { value: 'USER', label: t('web_partner_send_message_users') },
        { value: 'TAG', label: t('web_partner_send_message_tags') },
        { value: 'FRANCHISE', label: t('web_partner_send_message_franchises') },
    ]
    const [sendTo, setSendTo] = useState<(typeof sends)[0]>(sends[0])

    const currentLocale = localeFr

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    useEffect(() => {
        const load = async () => {
            await admin.loadBrand(brandUuid)
        }
        load()
    }, [brandUuid])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="title"
                error={
                    errors['title'] && `${t('web_menu_admin_information_message_title')} ${errors['title']?.message}`
                }
                label={'web_menu_admin_information_message_title'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('title')} type="text" className="w-1/3 " />
            </Label>
            <Label
                name="color"
                error={
                    errors['color'] && `${t('web_menu_admin_information_message_color')} ${errors['color']?.message}`
                }
                label={'web_menu_admin_information_message_color'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    control={control}
                    name="color"
                    render={({ field }) => (
                        <div className="grid w-2/3 grid-cols-6 gap-4">
                            {files.colors.map(c => (
                                <button
                                    key={c}
                                    className="relative h-6 w-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                    style={{
                                        backgroundColor: c === field.value ? 'white' : c,
                                        borderColor: c,
                                    }}
                                    onClick={() => field.onChange(c)}></button>
                            ))}
                        </div>
                    )}
                />
            </Label>
            <Label
                name="bodyMessage"
                error={
                    errors['bodyMessage'] &&
                    `${t('web_menu_admin_information_message_body')} ${errors['bodyMessage']?.message}`
                }
                label={'web_menu_admin_information_message_body'}
                className="my-5 flex w-full flex-wrap  items-center">
                <textarea {...register('bodyMessage')} className="w-1/3 " rows={3} />
            </Label>

            <Label
                name="dismissible"
                error={
                    errors['dismissible'] &&
                    `${t('web_menu_admin_information_dismissible')} ${errors['dismissible']?.message}`
                }
                label={'web_menu_admin_information_dismissible'}
                className={clsx('my-5 flex w-full flex-wrap items-center')}
                required={true}>
                <Controller
                    control={control}
                    name="dismissible"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="startDate"
                error={
                    errors['startDate'] &&
                    `${t('web_menu_admin_information_message_startDate')} ${errors['startDate']?.message}`
                }
                label={'web_menu_admin_information_message_startDate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} timepicker />
                    }}
                />
            </Label>
            <Label
                name="endDate"
                error={
                    errors['endDate'] &&
                    `${t('web_menu_admin_information_message_endDate')} ${errors['endDate']?.message}`
                }
                label={'web_menu_admin_information_message_endDate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} timepicker />
                    }}
                />
            </Label>
            <Label
                name="sendTo"
                label={'web_menu_admin_information_message_send_to'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Select
                    styles={selectStyles}
                    components={{ Input }}
                    className="w-1/3"
                    value={sendTo}
                    options={sends}
                    onChange={setSendTo}
                />
            </Label>
            <Label
                name="users"
                error={
                    errors['users'] && `${t('web_menu_partner_information_message_user')} ${errors['users']?.message}`
                }
                label={'web_menu_partner_information_message_user'}
                className={clsx('my-5 flex w-full flex-wrap items-center', {
                    block: sendTo.value === 'USER',
                    hidden: sendTo.value !== 'USER',
                })}>
                <Controller
                    name="users"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => {
                        return (
                            <AsyncSelect
                                {...field}
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                isClearable
                                isMulti
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    if (!brandUuid || admin.editingBrand.uuid === '') {
                                        return []
                                    }
                                    await admin.editingBrand.loadUsers(inputValue)

                                    return admin.editingBrand.users.map(u => ({
                                        value: u.uuid,
                                        label: `${u.fullname} (${u.email})`,
                                    }))
                                }}
                            />
                        )
                    }}
                />
            </Label>
            <Label
                name="tags"
                error={errors['tags'] && `${t('web_menu_partner_information_message_tags')} ${errors['tags']?.message}`}
                label={'web_menu_partner_information_message_tags'}
                className={clsx('my-5 flex w-full flex-wrap items-center', {
                    block: sendTo.value === 'TAG',
                    hidden: sendTo.value !== 'TAG',
                })}>
                <Controller
                    name="tags"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => {
                        return (
                            <AsyncSelect
                                {...field}
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                isClearable
                                isMulti
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    if (!brandUuid || admin.editingBrand.uuid === '') {
                                        return []
                                    }
                                    const tags = await brand.loadTags(admin.editingBrand.uuid, inputValue)

                                    return tags.map(tag => ({
                                        value: tag.uuid,
                                        label: tag.name,
                                    }))
                                }}
                            />
                        )
                    }}
                />
            </Label>
            <Label
                name="franchiseUuids"
                error={
                    errors['franchiseUuids'] &&
                    `${t('web_menu_partner_information_message_franchises')} ${errors['franchiseUuids']?.message}`
                }
                label={'web_menu_partner_information_message_franchises'}
                className={clsx('my-5 flex w-full flex-wrap items-center', {
                    block: sendTo.value === 'FRANCHISE',
                    hidden: sendTo.value !== 'FRANCHISE',
                })}>
                <Controller
                    name="franchiseUuids"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => {
                        return (
                            <AsyncSelect
                                {...field}
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                isClearable
                                isMulti
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    interface Data {
                                        franchises: {
                                            uuid: string
                                            name: string
                                        }[]
                                    }

                                    const { data } = await get<{ search?: string }, { data: Data }>(
                                        `/v1/web/brands/${brandUuid}/franchises`,
                                        {
                                            search: inputValue && inputValue.length > 0 ? inputValue : undefined,
                                        }
                                    )

                                    return data.franchises.map(franchise => ({
                                        value: franchise.uuid,
                                        label: franchise.name,
                                    }))
                                }}
                            />
                        )
                    }}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
        </form>
    )
}

export default Form
