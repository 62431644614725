import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { getDurationPeriods } from './form'

const Edit = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const [values, setValues] = useState<Record<string, unknown>>({})
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const onSubmit = async data => {
        const {
            name,
            description,
            longDescription,
            stripeProduct,
            stripePrice,
            durationPeriod,
            durationCount,
            free,
            manual,
            publicName,
            treasy,
            repeatable,
            storage,
            storageMessage,
            storageCta,
            connectors,
            connectorsMessage,
            connectorsCta,
            shareFiles,
            shareFilesMessage,
            shareFilesCta,
            transmission,
            transmissionMessage,
            transmissionCta,
            isDefault,
            tribe,
            upgrade,
        } = data
        setLoading(true)

        await admin.updatePricing(
            id,
            name,
            publicName,
            description,
            longDescription,
            {
                length: durationCount,
                period: durationPeriod?.value ?? null,
            },
            free,
            manual,
            treasy,
            storage,
            repeatable,
            connectors,
            shareFiles,
            transmission,
            isDefault,
            tribe,
            stripeProduct?.value ?? null,
            stripePrice?.value ?? null,
            storageMessage,
            storageCta,
            connectorsMessage,
            connectorsCta,
            shareFilesMessage,
            shareFilesCta,
            transmissionMessage,
            transmissionCta,
            upgrade?.value ?? null
        )

        setLoading(false)
        navigate('/admin/pricings')
    }

    const durationPeriods = getDurationPeriods(t)

    const load = async () => {
        setLoading(true)
        await admin.loadProducts()
        await admin.loadPrices()
        await admin.loadPricing(id)

        const upgrade =
            admin.editingPricing.upgrade && admin.editingPricing.upgrade.uuid !== ''
                ? admin.pricings.find(p => p.uuid === admin.editingPricing.upgrade.uuid)
                : null

        setValues({
            ...toJS(admin.editingPricing),
            durationCount: admin.editingPricing.duration.length,
            durationPeriod: durationPeriods.find(d => d.value === admin.editingPricing.duration.period),
            stripeProduct: admin.selectableProducts.find(p => p.value === admin.editingPricing.product),
            stripePrice: admin.selectablePrices.find(p => p.value === admin.editingPricing.price),
            upgrade: upgrade ? { value: upgrade.uuid, label: upgrade.name } : null,
            tribe: admin.editingPricing.tribe,
        })
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_menu_admin_pricing')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} currentId={id} />
        </Admin>
    )
}

export default Edit
