import { FC } from 'react'

interface Props {
    color: string
    title?: string
    link?: string
    linkLabel?: string
    message: string
    buttonMessage?: string
    onClick?: () => void
}

const InformationBar: FC<Props> = ({ color, message, buttonMessage, onClick, title, link, linkLabel }) => {
    return (
        <div
            className={'flex justify-between space-x-1 border-b border-white p-2 text-white'}
            style={{ backgroundColor: color }}>
            <div className="flex flex-col">
                {title && <h4 className="font-bold">{title}</h4>}
                <div className="flex flex-wrap gap-2">
                    <span>{message}</span>
                    {link && linkLabel && <a href={link}>{linkLabel}</a>}
                </div>
            </div>
            {buttonMessage && (
                <span onClick={onClick} className="cursor-pointer underline">
                    {buttonMessage}
                </span>
            )}
        </div>
    )
}

export default InformationBar
