import { clsx } from 'clsx'
import ToggleButton from 'components/shared/toggle-button'
import Tooltip from 'components/shared/tooltip'
import Config from 'core/config'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import type { Pricing as ModelPricing } from 'stores/pricing'
import { useMst } from 'stores/store'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import ProfilePage from './profile-page'
import { toJS } from 'mobx'

const Pricing = observer(() => {
    const { user, paymentManager } = useMst()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id } = useParams()

    const [pricing, setPricing] = useState<ModelPricing>()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isLoadingPayment, setLoadingPayment] = useState<boolean>(false)
    const [showYear, setShowYear] = useState<boolean>(true)

    const displayDuration = (pricing: ModelPricing): string => {
        if (pricing.duration.length === 1) {
            return `/${t(`web_pricing_period_${pricing.duration.period}`)}`
        }

        return t(`web_pricing_each_period_${pricing.duration.period}`, { length: pricing.duration.length })
    }

    const subscribe = async (pricing: ModelPricing) => {
        await paymentManager.subscribe(pricing)
    }

    const updateSubscription = async (pricing: ModelPricing) => {
        setShowModal(false)
        setLoadingPayment(true)

        const success = await paymentManager.updateSubscription(pricing)

        if (success) {
            navigate('/me/account')
        }

        setLoadingPayment(false)
    }

    const setPricingAndShowModal = async (pricing: ModelPricing | null, showModal: boolean) => {
        setPricing(pricing)
        setShowModal(showModal)
    }

    const load = useCallback(async () => {
        setLoadingPayment(true)
        await paymentManager.getStatus()
        setLoadingPayment(false)
    }, [paymentManager])

    useEffect(() => {
        load()
    }, [paymentManager])

    return (
        <ProfilePage url="/me/prices">
            <Panel className="mt-4" innerClassName="flex flex-col items-center justify-center bg-white space-y-6">
                <h2 className="text-lg font-bold">{t('web_me_choose_pricing')}</h2>
                {!isLoadingPayment && (
                    <div className="flex items-center justify-center gap-4">
                        <span>{t('web_pricing_period_per_month')}</span>
                        <ToggleButton value={showYear} onChange={setShowYear} />
                        <span>{t('web_pricing_period_per_year')}</span>
                    </div>
                )}
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:flex lg:justify-center lg:gap-4">
                    {isLoadingPayment ? (
                        <SmallLoader />
                    ) : (
                        paymentManager.pricings
                            .filter(pricing => pricing.repeatable)
                            .filter(pricing =>
                                showYear ? pricing.duration.period === 'years' : pricing.duration.period !== 'years'
                            )
                            .map(pricing => {
                                const currentSub = user.pricing.uuid === pricing.uuid && paymentManager.payment.cancelAt === null
                                const buttonDisabled =
                                    currentSub ||
                                    (user.pricing.isTribe && user.tribe.members.length - 1 > pricing.tribe - 1) // -1 for the current user

                                return (
                                    <div
                                        key={pricing.uuid}
                                        className={clsx('w-full rounded border bg-white shadow lg:max-w-md', {
                                            'border-4 border-christine': id === pricing.uuid,
                                            'mt-4': typeof id !== 'undefined' && id !== pricing.uuid,
                                        })}
                                    >
                                        <div className="border-b px-4 py-6">
                                            <h2 className="mb-6 text-xl font-bold">{pricing.publicName}</h2>
                                            <ReactMarkdown
                                                className="prose w-full max-w-full text-regent-gray"
                                                components={{
                                                    a: ({ node, href, children, ...props }) => {
                                                        if (
                                                            href.startsWith('http') &&
                                                            !href.startsWith(Config.app.WEBURL)
                                                        ) {
                                                            return (
                                                                <a
                                                                    href={href}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    {...props}
                                                                >
                                                                    {children}
                                                                </a>
                                                            )
                                                        }

                                                        return (
                                                            <a href={href} {...props}>
                                                                {children}
                                                            </a>
                                                        )
                                                    },
                                                }}
                                            >
                                                {pricing.description}
                                            </ReactMarkdown>
                                            <h4 className="my-6">
                                                <span className="text-3xl font-black">{pricing.displayPrice}</span>{' '}
                                                {displayDuration(pricing)}
                                            </h4>
                                            <Tooltip
                                                tooltip={
                                                    buttonDisabled
                                                        ? currentSub
                                                            ? t('web_subscription_current_sub')
                                                            : t(
                                                                  `web_subscription_disabled${
                                                                      pricing.tribe === 0 ? '_for_tribes' : ''
                                                                  }`,
                                                                  {
                                                                      pricingTribe: pricing.tribe - 1,
                                                                      userTribe: user.tribe.members.length - 1,
                                                                  }
                                                              )
                                                        : undefined
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    disabled={buttonDisabled}
                                                    className="btn premium w-full px-2"
                                                    onClick={() =>
                                                        paymentManager.payment.active && !user.pricing.free
                                                            ? setPricingAndShowModal(pricing, true)
                                                            : subscribe(pricing)
                                                    }
                                                >
                                                    {t('web_subscription_start', { name: pricing.name })}
                                                </button>
                                            </Tooltip>
                                        </div>
                                        <div className="px-4 py-6">
                                            <h5 className="text-sm font-bold uppercase">
                                                {t('web_subscription_whats_included')}
                                            </h5>
                                            <ReactMarkdown
                                                className="prose w-full max-w-full text-regent-gray"
                                                components={{
                                                    a: ({ node, href, children, ...props }) => {
                                                        if (
                                                            href.startsWith('http') &&
                                                            !href.startsWith(Config.app.WEBURL)
                                                        ) {
                                                            return (
                                                                <a
                                                                    href={href}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    {...props}
                                                                >
                                                                    {children}
                                                                </a>
                                                            )
                                                        }

                                                        return (
                                                            <a href={href} {...props}>
                                                                {children}
                                                            </a>
                                                        )
                                                    },
                                                }}
                                            >
                                                {pricing.longDescription}
                                            </ReactMarkdown>
                                        </div>
                                    </div>
                                )
                            })
                    )}
                    <Modal
                        isOpen={showModal}
                        onConfirm={() => updateSubscription(pricing)}
                        okLabel={t('web_subscription_change_confirm')}
                        onCancel={() => setPricingAndShowModal(null, false)}
                        onRequestClose={() => setPricingAndShowModal(null, false)}
                    >
                        <h4 className="my-4 text-center text-xl">
                            <Trans i18nKey="web_subscription_change_modal_title">
                                web_subscription_change_modal_title
                            </Trans>
                        </h4>
                    </Modal>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default Pricing
