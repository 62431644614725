import { InvokeCommand, LambdaClient, LogType } from '@aws-sdk/client-lambda'
import { faFileInvoice, faUserSecret } from '@fortawesome/pro-regular-svg-icons'
import {
    faClone,
    faCopy,
    faDownload,
    faPencil,
    faRotateRight,
    faShelves,
    faShuffle,
    faSpinner,
    faTransporter2,
    faTrash,
    faUsers,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import Loader from 'assets/backoffice/loader'
import Ocr from 'assets/dataroom/ocr'
import folder from 'assets/folder.svg'
import IconSpace from 'assets/icons/icon-space'
import clsx from 'clsx'
import LineUser from 'components/collaboration/line-user'
import LineUsers from 'components/collaboration/line-users'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { trigger } from 'core/events'
import { get, post } from 'core/services/http-service'
import { cn } from 'core/utils/cn'
import { mapOrJsonOptions } from 'core/utils/map-to-object'
import toast from 'core/utils/toast'
import { AnimatePresence, motion } from 'framer-motion'
import { DateTime } from 'luxon'
import { toJS, values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'
import { Link, useNavigate, useParams } from 'react-router-dom'
import type { FormatOptionLabelMeta, MultiValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast as reactToast } from 'react-toastify'
import type { Client } from 'stores/brands'
import type { CollaborationMessage } from 'stores/collaboration/message'
import { FileStatus, type SuggestionTag } from 'stores/files/suggestion'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'
import { ReactComponent as SendBill } from '../../assets/bills.svg'
import { ReactComponent as WomanSortedFile } from '../../assets/file-sorted.svg'
import IconDirectory from '../../assets/icons/icon-folder'
// import { ReactComponent as AnchorIcon } from '../../assets/icons/icon-anchor.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/icon-share.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/icon-trash.svg'
import IconUserDirectory from '../../assets/icons/icon-user-folder'
import { ReactComponent as MoveTo } from '../../assets/move-to.svg'
import { ReactComponent as CogIcon } from '../../assets/spaceroom/icon-cog.svg'
// import { ReactComponent as IconClipboard } from '../../assets/icons/icon-clipboard.svg'
import { ReactComponent as WomanTrash } from '../../assets/trash.svg'
import { ReactComponent as UploadImage } from '../../assets/upload.svg'
import Config from '../../core/config'
import { getFromQuery } from '../../core/use-query'
import { oxbridge } from '../../core/utils/array'
import type { DetachedSpaceOrDirectoryOrFile } from '../../stores/files'
import type { SpaceDirectory } from '../../stores/files/directory'
import type { GuestDirectory, GuestSpace, SpaceFile } from '../../stores/files/file'
import type { ClientCustomField, Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import type { CollaborationUserType } from '../../stores/users'
import Breadcrumb from '../shared/breadcrumb'
import FloatingBar from '../shared/floating-bar'
import FloatingBarButton from '../shared/floating-bar-button'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import Checklist from './checklist'
import ContentPage from './content-page'
import DirectoryModal from './directory-modal'
import CreateSpace from './create-space'
import MoveFile from './move-file'
import NoMatch from './no-match'
import ShareModalContent from './sharing/share-modal-content'
import SpaceroomMenu from './spaceroom-menu'
import { Directory } from './spaceroom/directory'
import { File, FileImage } from './spaceroom/file'
import Grid, { type Column } from './spaceroom/grid'
import { Line } from './spaceroom/line'
import { padding } from './spaceroom/props'
import { Row } from './spaceroom/row'
import { Space as TrSpace } from './spaceroom/space'
import { getFileSize } from './upload/file-size'
import RenameModal from './upload/rename-modal'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
import TrashFile, { type TrashProps } from './upload/trash-file'
import { ReactComponent as Restore } from '../../assets/icons/icon-restore.svg'
import { SpaceFileDocumentTypeModal, type SpaceFileWithDocumentType, type DocumentType } from './file-type-modal'
import { UsersDropdown } from 'components/collaboration/users-dropdown'

interface Destination {
    uuid: string
    type: 'directory' | 'space'
}

const DestinationDirectory = ({
    directory,
    level = 0,
    onSelect,
}: {
    directory: GuestDirectory
    level?: number
    onSelect: (destination: Destination) => void
}) => {
    return (
        <>
            <div
                className="flex items-center gap-2"
                style={{
                    padding: `.25rem .25rem .25rem ${padding(level)}rem`,
                }}
            >
                <input
                    type="radio"
                    name="destination"
                    onChange={() => onSelect({ uuid: directory.uuid, type: 'directory' })}
                />
                <span className="text-sm">{directory.name}</span>
            </div>
            {directory.directories?.map(child => (
                <DestinationDirectory key={child.uuid} directory={child} level={level + 1} onSelect={onSelect} />
            ))}
        </>
    )
}

const TabLine = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="3"
            viewBox="0 0 18 3"
            className={cn('stroke-current', className)}
        >
            <line x2="15" transform="translate(1.5 1.5)" fill="none" strokeLinecap="round" strokeWidth="3" />
        </svg>
    )
}

const EditCollaboration = ({
    isOpen,
    onClose,
    onRefresh,
    space,
}: {
    isOpen: boolean
    onClose: () => void
    onRefresh: () => void
    space: Space
}) => {
    const { t } = useTranslation()
    const { user } = useMst()

    type Option = { value?: string; label: string; options?: Option[] }

    const [selectedPeople, setSelectedPeople] = useState<MultiValue<Option>>([])
    const [submitting, setSubmitting] = useState<boolean>(false)

    const close = () => {
        setSelectedPeople(space.users.map(u => ({ value: u.uuid, label: `${u.fullname} (${u.email})` })))
        onClose()
    }

    useEffect(() => {
        setSelectedPeople(space.users.map(u => ({ value: u.uuid, label: `${u.fullname} (${u.email})` })))
    }, [space])

    const onEditSpace = async () => {
        setSubmitting(true)
        const sharedWith = selectedPeople.map(({ value }) => value)

        await space.update(space.name, space.color, null, space.description, sharedWith)
        toast('success', t('web_collaboration_edit_success'))

        setSubmitting(false)
        onRefresh()
        close()
    }

    return (
        <Modal
            overflowHidden={false}
            size="1/2"
            isOpen={isOpen}
            onRequestClose={() => {}}
            title={
                <div className="flex w-full items-center justify-start gap-4">
                    <div className="flex aspect-square items-center justify-center rounded-full bg-christine p-1 text-sm">
                        <FontAwesomeIcon icon={faUsers} fixedWidth className="text-white" />
                    </div>
                    <h4 className="text-center font-nunito text-xl font-bold">{t('web_collaboration_create_title')}</h4>
                </div>
            }
        >
            <div className="mb-1 mt-2 flex w-full flex-col">
                <div className="mb-8 flex w-full flex-col gap-4">
                    <UsersDropdown
                        selectedPeople={selectedPeople}
                        setSelectedPeople={setSelectedPeople}
                        space={space}
                    />
                </div>
                <div className="flex items-center justify-center space-x-4">
                    <button type="button" className="btn white" disabled={submitting} onClick={() => close()}>
                        {t('web_collaboration_create_cancel')}
                    </button>
                    <button type="submit" className="btn" disabled={submitting} onClick={() => onEditSpace()}>
                        {t('web_collaboration_create_save')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

type ActiveTab = 'details' | 'activity' | 'chat' | 'trash'

const ReplyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.557 13.845" className="h-4 w-4">
        <path
            data-name="Icon open-share"
            d="M6.088 1.162V4.75c7.175 0 8.969 3.677 8.969 8.969a6.878 6.878 0 0 0-7.175-5.382H6.088v3.588L.707 6.256Z"
            fill="none"
            stroke="#2f393e"
        />
    </svg>
)

const SendMessageIcon = ({ color = '#f15f12' }: { color?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.998" className="h-5 w-5">
        <path
            data-name="Icon ionic-ios-send"
            d="M17.432.04.223 7.54a.394.394 0 0 0 .014.717l4.66 2.631a.751.751 0 0 0 .858-.084l9.179-7.913c.061-.052.206-.15.263-.094s-.033.2-.084.263l-7.941 8.945a.748.748 0 0 0-.075.9l3.042 4.88a.4.4 0 0 0 .713-.009L17.962.56a.4.4 0 0 0-.53-.52Z"
            fill={color}
        />
    </svg>
)

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.655 23.31" className="h-5 w-5">
        <path
            data-name="Icon open-info"
            d="M8.741 0a2.914 2.914 0 1 0 2.914 2.914A2.922 2.922 0 0 0 8.741 0Zm-4.37 7.284A4.365 4.365 0 0 0 0 11.655h2.914a1.457 1.457 0 1 1 2.914 0c0 .816-2.914 4.779-2.914 7.284a4.31 4.31 0 0 0 4.37 4.371 4.365 4.365 0 0 0 4.371-4.371H8.741a1.457 1.457 0 0 1-2.914 0c0-1.049 2.914-5.361 2.914-7.284a4.4 4.4 0 0 0-4.37-4.371Z"
            fill="#8391a0"
        />
    </svg>
)

const ChatIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.286 22.286" className="h-5 w-5">
        <path
            data-name="Icon feather-message-circle"
            d="M21.036 10.593a9.212 9.212 0 0 1-.986 4.177 9.343 9.343 0 0 1-8.354 5.166 9.212 9.212 0 0 1-4.177-.989L1.25 21.036l2.089-6.266a9.212 9.212 0 0 1-.989-4.177 9.343 9.343 0 0 1 5.166-8.354 9.212 9.212 0 0 1 4.177-.989h.55a9.321 9.321 0 0 1 8.794 8.794Z"
            fill="none"
            stroke="#8391a0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
        />
    </svg>
)

const ChatIconButton = observer(({ onClick }: { onClick: () => void }) => {
    const { collaborationMessageStore } = useMst()

    return (
        <button
            type="button"
            onClick={onClick}
            className={cn('relative rounded-full bg-white flex items-center justify-center w-12 h-12 shadow-md')}
        >
            {collaborationMessageStore.unreadCount > 0 && (
                <span className="absolute right-0 top-1 z-20 flex h-5 w-5 items-center justify-center rounded-full bg-christine text-sm text-white">
                    {collaborationMessageStore.unreadCount}
                </span>
            )}
            <ChatIcon />
        </button>
    )
})

const TrashIconButton = observer(({ onClick }: { onClick: () => void }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={cn('relative rounded-full bg-white flex items-center justify-center w-12 h-12 shadow-md')}
        >
            <IconTrash className="inline-block fill-[#8391a0]" />
        </button>
    )
})

const MessageBox = ({
    collaborationId,
    inReplyTo,
    onMessageSaved,
    users,
}: {
    collaborationId: string
    inReplyTo?: CollaborationMessage
    onMessageSaved: (message: CollaborationMessage) => void
    users: CollaborationUserType[]
}) => {
    const { t } = useTranslation()
    const { collaborationMessageStore } = useMst()

    const [message, setMessage] = useState<string>('')
    const [submitting, setSubmitting] = useState<boolean>(false)

    const saveMessage = async () => {
        setSubmitting(true)

        const savedMessage = await collaborationMessageStore.postCollaborationMessage(
            collaborationId,
            message,
            inReplyTo
        )

        if (!savedMessage) {
            toast('error', t('web_collaboration_chat_message_save_error'))
        } else {
            onMessageSaved(savedMessage)
            setMessage('')
        }

        setSubmitting(false)
    }

    const toolbarCommands = [['bold', 'italic'], ['link']]
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (!textAreaRef.current) {
            return
        }

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && event.shiftKey) {
                event.preventDefault()
                if (message.trim().length === 0) {
                    return
                }
                void saveMessage()
            }
        }

        textAreaRef.current.addEventListener('keydown', onKeyDown)

        return () => {
            if (!textAreaRef.current) {
                return
            }
            textAreaRef.current.removeEventListener('keydown', onKeyDown)
        }
    }, [textAreaRef, message])

    return (
        <div className="relative flex w-full flex-col gap-2">
            <ReactMde
                classes={{
                    suggestionsDropdown: ['z-10'],
                }}
                minEditorHeight={80}
                maxEditorHeight={80}
                value={message}
                onChange={setMessage}
                disablePreview={true}
                toolbarCommands={toolbarCommands}
                refs={{ textarea: textAreaRef }}
                loadSuggestions={async (text: string, triggeredBy: string) => {
                    const toLower = text.toLowerCase()

                    return await Promise.all(
                        users
                            .filter(
                                user =>
                                    user.username.toLowerCase().startsWith(toLower) ||
                                    user.fullname.toLowerCase().startsWith(toLower)
                            )
                            .map(({ uuid, fullname, username, picture }) => ({
                                preview: (
                                    <span className="relative flex items-center gap-2">
                                        <LineUser user={{ uuid, fullname, picture }} zIndex={1} size="sm" />
                                        <span className="text-sm">
                                            {fullname} ({username})
                                        </span>
                                    </span>
                                ),
                                value: `[user:${username}]`,
                            }))
                    )
                }}
                suggestionTriggerCharacters={['@']}
            />
            <Tippy content={t('web_collaboration_chat_message_submit')} disabled={submitting}>
                <button
                    className="absolute bottom-8 right-4"
                    onClick={async () => await saveMessage()}
                    disabled={message.trim().length === 0 || submitting}
                >
                    {submitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin className="text-atomic-tangerine" />
                    ) : (
                        <SendMessageIcon color={message.trim().length === 0 ? '#8391A0' : undefined} />
                    )}
                </button>
            </Tippy>
            <span className="text-xs italic text-pale-sky">{t('web_collaboration_chat_message_help')}</span>
        </div>
    )
}

const MessageLine = ({
    message,
    withReplies = true,
    isReply = false,
    users,
    secureChat,
}: {
    message: CollaborationMessage
    withReplies?: boolean
    isReply?: boolean
    users: CollaborationUserType[]
    secureChat: boolean
}) => {
    const { t } = useTranslation()
    const [reply, setReply] = useState<boolean>(false)

    const {
        user: { uuid, fullname, picture },
        message: text,
    } = message

    const svgs = Array((1 + message.replies.length) * 3)
        .fill(0)
        .map((_, index) => index)

    return (
        <div className={clsx('flex flex-col gap-2', secureChat && 'select-none relative')}>
            {secureChat && !isReply && (
                <div className="absolute opacity-10 inset-0 text-gray-800 select-none flex flex-wrap items-center justify-center pointer-events-none">
                    {svgs.map(value => (
                        <svg key={value} xmlns="http://www.w3.org/2000/svg" version="1.1" height="150px" width="150px">
                            <title>{t('web_chat_confidential')}</title>
                            <text
                                transform="translate(20, 120) rotate(-45)"
                                fill="currentColor"
                                fontFamily="Special Elite"
                                fontSize="28"
                            >
                                {t('web_chat_confidential')}
                            </text>
                        </svg>
                    ))}
                </div>
            )}
            <div className={clsx('flex items-center gap-1 px-3 text-sm', isReply ? '' : 'pt-3')}>
                <LineUser user={{ uuid, fullname, picture }} zIndex={1} size="sm" />
                <span className="shrink-0">{fullname}</span>
                <span className="shrink-0 grow text-right font-semibold text-another-gray">
                    {DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_MED)}
                </span>
            </div>
            <div className={clsx('px-3 text-sm', isReply ? 'pl-9' : '')}>
                <ReactMarkdown
                    className="prose text-sm"
                    components={{
                        a: ({ node, href, children, ...props }) => {
                            if (href.startsWith('http') && !href.startsWith(Config.app.WEBURL)) {
                                return (
                                    <a
                                        href={href}
                                        className="text-atomic-tangerine"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        {...props}
                                    >
                                        {children}
                                    </a>
                                )
                            }

                            return (
                                <a href={href} className="text-atomic-tangerine" {...props}>
                                    {children}
                                </a>
                            )
                        },
                    }}
                >
                    {text}
                </ReactMarkdown>
            </div>
            {withReplies && (
                <div>
                    <button
                        onClick={() => setReply(true)}
                        type="button"
                        className={clsx(
                            'flex w-full items-center justify-center gap-2 border-t border-mercury py-2 text-sm',
                            message.replies.length > 0 ? 'border-b' : '',
                            reply ? 'hidden' : ''
                        )}
                    >
                        <ReplyIcon />
                        <span>{t('web_collaboration_chat_reply')}</span>
                    </button>
                    {reply && (
                        <div className="p-2">
                            <MessageBox
                                onMessageSaved={(message: CollaborationMessage) => {
                                    //
                                }}
                                collaborationId={message.collaborationId}
                                inReplyTo={message}
                                users={users}
                            />
                            <button onClick={() => setReply(false)} type="button" className="text-xs text-another-gray">
                                {t('web_collaboration_chat_reply_cancel')}
                            </button>
                        </div>
                    )}
                    {message.replies.length > 0 && (
                        <div className="flex flex-col gap-4 bg-yet-another-gray py-3">
                            {message.replies.map(reply => (
                                <MessageLine
                                    key={reply.id}
                                    message={reply}
                                    withReplies={false}
                                    isReply={true}
                                    users={users}
                                    secureChat={secureChat}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

const TrashedFile = observer(({ file, onDeselect, onSelect, selected }: TrashProps) => {
    const [isOver, setOver] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(false)

    const changeSelection = newValue => {
        newValue ? onSelect(file) : onDeselect(file)
        setChecked(newValue)
    }

    const toggleChecked = () => {
        const newValue = !checked
        changeSelection(newValue)
    }

    useEffect(() => {
        setChecked(selected)
    }, [selected])

    return (
        <div className="grid grid-cols-12 gap-2">
            <div
                className={clsx(
                    'flex items-center justify-center border-l-2 border-christine',
                    isOver || checked ? 'border-r-0 bg-christine' : ''
                )}
                onMouseEnter={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => toggleChecked()}
                    className="border-another-gray"
                />
            </div>
            <div className={clsx('col-span-11')}>
                <div className="flex items-center gap-3">
                    <FileImage file={file} filename={file.name} />
                    <span className="text-sm truncate" title={file.name}>
                        {file.name}
                    </span>
                </div>
                <span className="text-sm truncate" title={file.directory.name}>
                    {file.space.displayName} &gt; {file.directory.name}
                </span>
            </div>
        </div>
    )
})

const TrashedFilesList = observer(({ space, isOpened }: { space: Space; isOpened: boolean }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedAll, setSelectedAll] = useState<boolean>(false)
    const [selectedFiles, setSelectedFiles] = useState<SpaceFile[]>([])

    const restore = async () => {
        setLoading(true)
        await space.restoreFiles(selectedFiles)
        setLoading(false)
        setSelectedFiles([])
        toast('success', t('web_collaboration_space_files_restored_success'))
    }

    const changeSelection = (file: SpaceFile, selected: boolean) => {
        const selection = [...selectedFiles]
        if (selected) {
            selection.push(file)
        } else {
            const index = selection.findIndex(s => s.uuid === file.uuid)
            if (index === -1) {
                return
            }
            selection.splice(index, 1)
        }
        setSelectedFiles(selection)
    }

    return (
        <>
            <div className="flex flex-col gap-4">
                <h3 className="font-bold">{t('web_collaboration_space_deleted_files')}</h3>
                <p className="text-sm">{t('web_collaboration_space_deleted_files_help')}</p>
                <div className="flex flex-col gap-2">
                    {loading ? (
                        <SmallLoader />
                    ) : (
                        space.trash.map(file => (
                            <TrashedFile
                                key={file.uuid}
                                file={file}
                                selected={selectedAll}
                                onSelect={s => changeSelection(s, true)}
                                onDeselect={s => changeSelection(s, false)}
                            />
                        ))
                    )}
                </div>
                {!loading && selectedFiles.length > 0 && (
                    <div className="flex">
                        <button
                            type="button"
                            onClick={() => restore()}
                            className="text-white bg-christine rounded-md flex gap-2 items-center p-2 px-4 shadow-md"
                        >
                            <Restore className="h-4 fill-white" />
                            <span>{t('web_trash_restore_file')}</span>
                        </button>
                    </div>
                )}
            </div>
        </>
    )
})

const CollaborationMessages = observer(({ space, isOpened }: { space: Space; isOpened: boolean }) => {
    const { t } = useTranslation()
    const { collaborationMessageStore, user } = useMst()
    const [loading, setLoading] = useState<boolean>(true)

    const [collabUsers, setCollabUsers] = useState<CollaborationUserType[]>([])

    const secureChat = !!(space.jsonOptions?.secure_collaboration ?? false)

    const load = async (collaborationId: string, isOpened: boolean) => {
        setLoading(true)

        collaborationMessageStore.setPanelOpened(isOpened)
        await collaborationMessageStore.loadCollaborationMessages(collaborationId)

        const users = space.users.map(({ uuid, username, fullname, picture }) => ({
            uuid,
            username,
            fullname,
            picture,
        })) as CollaborationUserType[]

        if (space.collaborationOwner) {
            const { uuid, username, fullname, picture } = space.collaborationOwner
            users.push({ uuid, username, fullname, picture })
        }

        setCollabUsers(users)
        setLoading(false)
    }

    const flagMessagesRead = async (collaborationId: string) => {
        await collaborationMessageStore.flagMessagesRead(collaborationId)
    }

    useEffect(() => {
        if (!space) {
            return
        }
        load(space.collaborationId, isOpened)
    }, [space?.collaborationId, isOpened])

    useEffect(() => {
        if (collaborationMessageStore.currentCollaborationId !== space.collaborationId) {
            return
        }

        const loadMessages = async () => {
            collaborationMessageStore.setPanelOpened(isOpened)
            await load(collaborationMessageStore.currentCollaborationId, isOpened)
            if (isOpened) {
                await flagMessagesRead(collaborationMessageStore.currentCollaborationId)
            }
            collaborationMessageStore.setCurrentCollaborationId('')
        }
        loadMessages()
    }, [isOpened, collaborationMessageStore.currentCollaborationId, space?.collaborationId])

    return (
        <div className="flex flex-col gap-4 px-2">
            {secureChat && <p className="text-atomic-tangerine">{t('web_chat_confidential_desc')}</p>}
            <MessageBox
                onMessageSaved={(message: CollaborationMessage) => {
                    // TODO refresh stuff
                }}
                collaborationId={space.collaborationId}
                users={collabUsers}
            />
            {loading && collaborationMessageStore.messages.length === 0 ? (
                <div className="pt-4">
                    <SmallLoader />
                </div>
            ) : collaborationMessageStore.messages.length > 0 && isOpened ? (
                <AnimatePresence>
                    {collaborationMessageStore.messages.map(message => {
                        const read = message.read
                        const repliesRead =
                            message.replies?.filter(({ read }) => read).length === message.replies.length ?? true
                        const isRead = read && repliesRead

                        return (
                            <motion.div
                                key={message.id}
                                className={clsx('overflow-hidden rounded-xl bg-white', 'border border-mercury')}
                                transition={{ duration: 1.5 }}
                                initial={
                                    !isRead
                                        ? {
                                              boxShadow:
                                                  'var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) #FDA86A',
                                          }
                                        : {}
                                }
                                animate={
                                    !isRead
                                        ? {
                                              boxShadow:
                                                  'var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) #FDA86A',
                                          }
                                        : {}
                                }
                            >
                                <MessageLine message={message} users={collabUsers} secureChat={secureChat} />
                            </motion.div>
                        )
                    })}
                </AnimatePresence>
            ) : (
                <p className="text-sm">{t('web_collaboration_chat_no_messages')}</p>
            )}
        </div>
    )
})

const SpaceInformationPanel = observer(
    ({
        show,
        onClose,
        onEditTitle,
        space,
        activeTab = 'details',
    }: {
        show: boolean
        onClose: () => void
        onEditTitle?: () => void
        space: Space
        activeTab?: ActiveTab
    }) => {
        const { t } = useTranslation()
        const { user } = useMst()
        const [tab, setTab] = useState<ActiveTab>()

        useEffect(() => {
            setTab(activeTab)
        }, [activeTab])

        const navigate = useNavigate()

        const formatter = new Intl.ListFormat(user.locale, { style: 'long', type: 'conjunction' })

        const postLeaveMutation = useMutation({
            mutationFn: id => post<never, never>(`/v1/web/collaboration/leave/${id}`),
            onSuccess: () => {
                navigate('/collaboration')
                toast('success', t('web_collaboration_leave_success'))
            },
        })
        const [showConfirmLeave, setShowConfirmLeave] = useState<boolean>(false)
        const [showEditAccess, setShowEditAccess] = useState<boolean>(false)
        const [showEditDescription, setShowEditDescription] = useState<boolean>(false)
        const [isSecure, setIsSecure] = useState<boolean>(false)
        const [description, setDescription] = useState<string>()
        const [isLoading, setLoading] = useState<boolean>(false)
        const [canEdit, setCanEdit] = useState<boolean>(false)

        const leaveOrEditAccess = () => {
            if (space.collaborationManagers.includes(user.uuid)) {
                setShowEditAccess(true)
            } else {
                setShowConfirmLeave(true)
            }
        }

        const config = mapOrJsonOptions(toJS(space.brandConfig))
        const canSecure = !!(config.secure_collaboration ?? false)

        const saveDescription = async () => {
            await space.update(undefined, undefined, undefined, description)
            toast('success', t('web_collaboration_edit_description_success'))
            setShowEditDescription(false)
        }

        const updateSecure = async (secure: boolean) => {
            setLoading(true)
            const newValue = await space.toggleSecure(secure)
            setIsSecure(newValue)
            setLoading(false)
        }

        useEffect(() => {
            setDescription(space.description)
            setCanEdit(user.canManagePersonalData && space.userUuid === user.uuid)
            setIsSecure(space.jsonOptions?.secure_collaboration ?? false)
        }, [user, space])

        return (
            <Panel
                noSize
                className={cn('absolute right-2 top-2 w-[450px] bottom-32', show ? '' : 'translate-x-[32rem]')}
                innerPadding="p-6 px-7"
                innerClassName="bg-white h-full relative"
            >
                <button
                    type="button"
                    onClick={() => onClose()}
                    className="flex h-12 w-12 items-center justify-center rounded-full border border-christine bg-white shadow-md absolute -top-6 -left-6"
                >
                    <FontAwesomeIcon icon={faXmark} className=" text-xl text-christine" />
                </button>
                <div className="absolute -left-6 bottom-5 z-20 flex flex-col gap-3">
                    {space.isShared ? (
                        <>
                            <button
                                type="button"
                                onClick={() => setTab('details')}
                                className={cn(
                                    'relative rounded-full bg-white flex items-center justify-center w-12 h-12 shadow-md'
                                )}
                            >
                                <InfoIcon />
                            </button>
                            <ChatIconButton onClick={() => setTab('chat')} />
                            {space.collaborationManagers.includes(user.uuid) && (
                                <TrashIconButton onClick={() => setTab('trash')} />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={cn(tab === 'chat' ? 'visible' : 'hidden', 'absolute inset-0 p-6 z-10')}>
                    <div className="h-full overflow-y-auto">
                        <CollaborationMessages space={space} isOpened={tab === 'chat'} />
                    </div>
                </div>
                <div className={cn(tab === 'trash' ? 'visible' : 'hidden')}>
                    <div className="h-full overflow-y-auto">
                        <TrashedFilesList space={space} isOpened={tab === 'trash'} />
                    </div>
                </div>
                <div className={cn(tab === 'details' ? 'visible' : 'hidden')}>
                    <div className="flex items-center justify-start gap-2 text-xl">
                        <IconSpace
                            className="w-10 flex-none fill-current"
                            style={{ color: space.color }}
                            isShared={space.isShared}
                        />
                        <span>{space.displayNameForUser(user)} </span>
                        {canEdit && (
                            <Tippy content={t('web_spaceroom_rename')}>
                                <button type="button" onClick={() => onEditTitle?.()}>
                                    <FontAwesomeIcon icon={faPencil} className="shrink-0" />
                                </button>
                            </Tippy>
                        )}
                    </div>
                    {canEdit && canSecure && space.isShared && (
                        <div className="mt-4 flex justify-end gap-4">
                            <span className="text-regent-gray">{t('web_collaboration_edit_space_secure')}</span>
                            {isLoading ? (
                                <Loader loading={isLoading} className="text-christine" />
                            ) : (
                                <ToggleButton value={isSecure} onChange={secure => updateSecure(secure)} />
                            )}
                        </div>
                    )}
                    <div className="mt-4 flex flex-col items-end justify-between gap-3 italic">
                        {showEditDescription ? (
                            <textarea
                                className="h-40 w-full"
                                value={description}
                                onChange={e => setDescription(e.currentTarget.value)}
                            />
                        ) : (
                            <p className="break-words max-w-full">{description}</p>
                        )}
                        {user.canManagePersonalData && space.userUuid === user.uuid && (
                            <>
                                {showEditDescription ? (
                                    <div className="flex w-full justify-between">
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={() => setShowEditDescription(false)}
                                        >
                                            {t('web_collaboration_edit_cancel')}
                                        </button>
                                        <button type="button" className="btn" onClick={() => saveDescription()}>
                                            {t('web_collaboration_edit_save')}
                                        </button>
                                    </div>
                                ) : (
                                    <button type="button" className="btn" onClick={() => setShowEditDescription(true)}>
                                        {t('web_collaboration_edit_description')}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <div className="mt-4">
                        {space.deletedAt && (
                            <div className="flex items-center justify-start gap-3 border-b border-b-geyser py-2 text-sm font-semibold">
                                <span className="text-christine">
                                    {t(
                                        space.users.find(u => u.id === user.uuid)?.deletedBy === user.uuid
                                            ? 'web_collaboration_left'
                                            : 'web_collaboration_deleted',
                                        {
                                            name: space.username,
                                            date: DateTime.fromISO(space.deletedAt).toLocaleString(
                                                DateTime.DATETIME_MED
                                            ),
                                        }
                                    )}
                                </span>
                            </div>
                        )}

                        {space.isShared && (
                            <>
                                {space.users.length > 0 && (
                                    <div>
                                        <h3 className="font-bold">{t('web_collaboration_space_access')}</h3>
                                        <p>
                                            {t('web_collaboration_space_access_with', {
                                                names: formatter.format(space.users.map(u => u.fullname)),
                                            })}
                                        </p>
                                    </div>
                                )}
                                <div className="mt-2 flex items-center justify-between">
                                    <LineUsers users={space.users} />
                                    {user.canManagePersonalData && !space.shareLocked && (
                                        <button type="button" className="btn" onClick={() => leaveOrEditAccess()}>
                                            {space.collaborationManagers.includes(user.uuid)
                                                ? t('web_collaboration_space_edit_access')
                                                : t('web_collaboration_space_leave')}
                                        </button>
                                    )}
                                </div>
                                {space.collaborationManagers.includes(user.uuid) && space.invitations.length > 0 && (
                                    <div className="mt-2">
                                        <h3 className="font-bold">{t('web_collaboration_pending_invitations')}</h3>
                                        <p>{formatter.format(space.invitations)}</p>
                                    </div>
                                )}
                                <Modal
                                    isOpen={showConfirmLeave}
                                    onCancel={() => setShowConfirmLeave(false)}
                                    onRequestClose={() => setShowConfirmLeave(false)}
                                    cancelLabel={t('web_collaboration_leave_cancel')}
                                    okLabel={t('web_collaboration_leave_confirm')}
                                    onConfirm={() => {
                                        setShowConfirmLeave(false)
                                        postLeaveMutation.mutate(space.uuid)
                                    }}
                                >
                                    <h4 className="my-4 text-center text-xl">
                                        <Trans i18nKey="web_collaboration_leave_title">web_dataroom_trash_space</Trans>
                                    </h4>
                                    <span className="text-sm text-regent-gray">
                                        {t('web_collaboration_leave_desc')}
                                    </span>
                                </Modal>
                                <EditCollaboration
                                    space={space}
                                    isOpen={showEditAccess}
                                    onClose={() => setShowEditAccess(false)}
                                    onRefresh={() => {}}
                                />
                            </>
                        )}
                        <div className="mt-4">
                            <h3 className="font-bold">{t('web_collaboration_space_informations')}</h3>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="text-regent-gray">{t('web_collaboration_space_creator')}</td>
                                        <td className="text-right">{space.username}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-regent-gray">{t('web_collaboration_space_size')}</td>
                                        <td className="text-right">{getFileSize(t, space.size)}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-regent-gray">{t('web_collaboration_space_created_at')}</td>
                                        <td className="text-right">
                                            {DateTime.fromMillis(space.dateAdded).toLocaleString(
                                                DateTime.DATETIME_SHORT
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-regent-gray">
                                            {t('web_collaboration_space_creator_updated_at')}
                                        </td>
                                        <td className="text-right">
                                            {DateTime.fromMillis(space.lastModified).toLocaleString(
                                                DateTime.DATETIME_SHORT
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Panel>
        )
    }
)

const DestinationSpace = ({
    space,
    level = 0,
    onSelect,
}: {
    space: GuestSpace
    level?: number
    onSelect: (destination: Destination) => void
}) => {
    return (
        <>
            <div
                className="flex items-center gap-2"
                style={{
                    padding: `.25rem .25rem .25rem ${padding(level)}rem`,
                }}
            >
                <input type="radio" name="destination" onChange={() => onSelect({ uuid: space.uuid, type: 'space' })} />
                <span className="text-sm">{space.name}</span>
            </div>
            {space.spaces?.map(child => (
                <DestinationSpace key={child.uuid} space={child} level={level + 1} onSelect={onSelect} />
            ))}
            {space.directory?.flatMap(d =>
                d.directories?.map(child => (
                    <DestinationDirectory key={child.uuid} directory={child} level={level + 1} onSelect={onSelect} />
                ))
            )}
        </>
    )
}

export const cmp = (a: SpaceFile | SpaceDirectory, b: SpaceFile | SpaceDirectory) => {
    if (a.createdAt.hasSame(b.createdAt, 'millisecond')) {
        return 0
    }

    return a.createdAt > b.createdAt ? -1 : 1
}

const Spaceroom = observer(() => {
    const { t } = useTranslation()
    const params = useParams()
    const uuids = params['*'].split('/')
    // const id = uuids.pop()
    const [id] = uuids

    const navigate = useNavigate()
    const { files, user } = useMst()

    const highlightedFile = getFromQuery('f')
    const highlightedDirectory = getFromQuery('d')
    const highlightedSpace = getFromQuery('s')

    const [client, setClient] = useState<Client>(undefined)
    const [clientCustomFields, setClientCustomFields] = useState<ClientCustomField[]>([])

    const [space, setSpace] = useState<Space>()
    const [currentSpace, setCurrentSpace] = useState<Space | undefined>(undefined)
    const [currentDirectory, setCurrentDirectory] = useState<SpaceDirectory | undefined>(undefined)
    const [createUserDirectory, setCreateUserDirectory] = useState<boolean>(false)
    const [isStack, setIsStack] = useState<boolean>(false)

    const [columns, setColumns] = useState<Column[]>([])

    const [loading, setLoading] = useState<boolean>(false)

    const [isLoaded, setLoaded] = useState<boolean>(false)

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [showSpaceroomMenu, setShowSpaceroomMenu] = useState<boolean>(false)

    const [showTransferModal, setShowTransferModal] = useState<boolean>(false)
    const [newUser, setNewUser] = useState<{ value: string; label: string }>()
    const [newUserDestination, setNewUserDestination] = useState<{ uuid: string; type: 'directory' | 'space' }>()
    const [newUserAvailableDestinations, setNewUserAvailableDestinations] = useState<GuestSpace[]>()

    const [canOcr, setCanOcr] = useState<boolean>(false)

    const [canRefresh, setCanRefresh] = useState<boolean>(false)
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

    const [showAskOcr, setShowAskOcr] = useState<boolean>(false)
    const [showMoveTo, setShowMoveTo] = useState<boolean>(false)
    const [showCopyTo, setShowCopyTo] = useState<boolean>(false)
    const [askForSelectParent, setAskForSelectParent] = useState<string[]>([])
    const [askKeepRule, setAskKeepRule] = useState<boolean>(false)

    const [showShareLink, setShowShareLink] = useState<boolean>(false)
    const [showSendBill, setSendBill] = useState<boolean>(false)

    const [showCreateSpaceModal, setShowCreateSpaceModal] = useState<boolean>(false)
    const [showCreateDirectoryModal, setShowCreateDirectoryModal] = useState<boolean>(false)

    const [selectedFiles, setSelectedFiles] = useState<DetachedSpaceOrDirectoryOrFile[]>([])

    const [fileExists, setFileExists] = useState<boolean>(false)

    const [showRenameModal, setShowRenameModal] = useState<SpaceFile | undefined>()
    const [suggestion, setSuggestion] = useState<SpaceFile | undefined>()

    const [fileDocumentTypeModal, setFileDocumentTypeModal] = useState<SpaceFile[] | undefined>()
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])

    const loadDocumentTypes = async () => {
        const data = await get<void, { data: { types: DocumentType[] } }>('/v1/web/checklist/document-types')
        const {
            data: { types },
        } = data

        setDocumentTypes(types)
    }

    useEffect(() => {
        loadDocumentTypes()
    }, [])

    const collator = new Intl.Collator(user.locale, { numeric: true })

    const [brandConfig, setBrandConfig] = useState<Record<string, unknown>>()
    useEffect(() => {
        if (user.currentFranchise) {
            const config = mapOrJsonOptions(toJS(user.currentFranchise.brandConfig))
            setBrandConfig(config)
        }
    }, [user])

    const changeColumns = (isStack: boolean) => {
        setColumns(
            [
                {
                    label: t('web_spaceroom_file_name'),
                    className: isStack ? 'text-left col-span-9' : 'text-left',
                    style: isStack ? undefined : { gridColumn: 'span 15 / span 15' },
                },
                isStack
                    ? {
                          label: t('web_dataroom_add_file_suggestion_short'),
                          className: 'col-span-6 text-center',
                      }
                    : undefined,
                { label: t('web_spaceroom_document_date'), className: 'col-span-2 text-center' },
                { label: t('web_spaceroom_file_type'), className: 'col-span-2 text-center' },
                { label: t('web_spaceroom_file_size'), className: 'col-span-2 text-center' },
            ].filter(c => c !== undefined)
        )
    }

    const getConfig = async () => await files.getConfig()

    const load = async (id: string) => {
        let space = files.recursivelyFindSpace(id)

        if (user.readOnly && space) {
            if (
                !(
                    (space.userUuid === user.uuid && space.jsonOptions?.displayOn?.user === 'menu') ||
                    (space.userUuid !== user.uuid && space.jsonOptions?.displayOn?.others === 'menu')
                )
            ) {
                space = null
            }
        }

        if (!files.shouldForceRefresh(id) && space && space.isLoaded) {
            setLoaded(true)
            setSpace(space)
            setIsStack(space.isStack)
            changeColumns(space.isStack)

            return
        }

        if (!space || files.shouldForceRefresh(id)) {
            await files.getFiles(files.shouldForceRefresh(space?.uuid))
            space = files.recursivelyFindSpace(id)
        }

        if (!space) {
            return
        }

        await space.refresh(files.shouldForceRefresh(id))
        space = files.recursivelyFindSpace(id)

        setSpace(space)
        setIsStack(space.isStack)
        changeColumns(space.isStack)
        setLoaded(true)
        files.clearForceRefresh(space.uuid)
    }

    useEffect(() => {
        if (space) {
            setClientCustomFields(space.clientCustomFields)
        }
    }, [space])

    const refresh = async (space: Space | undefined, directory: SpaceDirectory | undefined) => {
        const uuids: string[] = []
        if (directory) {
            if (directory.isRootDirectory) {
                uuids.push(directory.spaceUuid)
            } else {
                uuids.push(directory.uuid)
            }
        } else if (space) {
            uuids.push(space.uuid)
        }

        if (uuids.length > 0) {
            files.markForceRefresh(uuids)
        }
    }

    useEffect(() => {
        if (!isLoaded || (space && space.uuid !== id) || (space && !space.isLoaded)) {
            getConfig()
            load(id)
        }
    }, [isLoaded, id])

    useEffect(() => {
        if (space && files.forceRefresh.length > 0) {
            load(space.uuid)
        }
    }, [space, files.forceRefresh])

    const changeSelection = (object: SpaceFile | SpaceDirectory | Space, selected: boolean) => {
        const selection = [...selectedFiles]
        if (selected) {
            // need to remove children if needed
            const recursivelyRemoveDirectory = (directory: SpaceDirectory) => {
                const children = values<Space>(directory.directories)
                for (const child of children) {
                    recursivelyRemoveDirectory(child)
                }

                const files = values<SpaceFile>(directory.files)
                for (const file of files) {
                    const index = selection.findIndex(s => s.uuid === file.uuid)
                    if (index > -1) {
                        selection.splice(index, 1)
                    }
                }

                const index = selection.findIndex(s => s.uuid === directory.uuid)
                if (index > -1) {
                    selection.splice(index, 1)
                }
            }
            const recursivelyRemoveSpace = (space: Space) => {
                const children = values<Space>(space.spaces)
                for (const child of children) {
                    recursivelyRemoveSpace(child)
                }
                recursivelyRemoveDirectory(space.rootDirectory)

                const index = selection.findIndex(s => s.uuid === space.uuid)
                if (index > -1) {
                    selection.splice(index, 1)
                }
            }
            if (object.isSpace) {
                recursivelyRemoveSpace(object)
            } else if (object.isDirectory) {
                recursivelyRemoveDirectory(object)
            }

            selection.push({
                displayName: object.displayName,
                uuid: object.uuid,
                isFile: object.isFile,
                isDirectory: object.isDirectory,
                isSpace: object.isSpace,
                isEditable: object.isEditableBy(user),
                isDeletable: object.isDeletableBy(user),
                parentUuid: object.parentUuid,

                connector: object.connector,
                connectorUuid: object.connectorUuid,
                subscriber: object.subscriber,
                label: object.label,
                subConnector: object.subConnector,

                storeStatus: object.storeStatus,

                canRefresh: object.canRefresh,
                object,
            })
        } else {
            const index = selection.findIndex(s => s.uuid === object.uuid)
            if (index === -1) {
                return
            }
            selection.splice(index, 1)
        }

        const files = selection.filter(
            (fileOrDirectory, index, self) => self.findIndex(s => fileOrDirectory.uuid === s.uuid) === index
        )
        setSelectedFiles(files)
    }

    const toggleAll = (selectedAll: boolean) => {
        if (!space) {
            return
        }

        if (selectedAll) {
            const selection: DetachedSpaceOrDirectoryOrFile[] = []

            const spaces = values<Space>(space.spaces)
            for (const space of spaces) {
                selection.push({
                    displayName: space.displayName,
                    uuid: space.uuid,
                    isFile: false,
                    isDirectory: false,
                    isSpace: true,
                    isEditable: space.isEditableBy(user),
                    isDeletable: space.isDeletableBy(user),
                    parentUuid: space.parentUuid,
                    connector: space.connector,
                    connectorUuid: space.connectorUuid,
                    subscriber: space.subscriber,
                    label: space.label,
                    subConnector: space.subConnector,
                    storeStatus: space.storeStatus,
                })
            }

            const directories = values<SpaceDirectory>(space.rootDirectory.directories)
            for (const directory of directories) {
                selection.push({
                    displayName: directory.displayName,
                    uuid: directory.uuid,
                    isFile: false,
                    isDirectory: true,
                    isSpace: false,
                    isEditable: directory.isEditableBy(user),
                    isDeletable: directory.isDeletableBy(user),
                    parentUuid: directory.parentUuid,
                    connector: directory.connector,
                    connectorUuid: directory.connectorUuid,
                    subscriber: directory.subscriber,
                    label: directory.label,
                    subConnector: directory.subConnector,
                    storeStatus: directory.storeStatus,
                    canRefresh: directory.canRefresh,
                })
            }
            const files = values<SpaceFile>(space.rootDirectory.files)
            for (const file of files) {
                selection.push({
                    displayName: file.displayName,
                    uuid: file.uuid,
                    isFile: true,
                    isDirectory: false,
                    isSpace: false,
                    isEditable: file.isEditableBy(user),
                    isDeletable: file.isDeletableBy(user),
                    parentUuid: file.parentUuid,
                    connector: space.connector,
                    connectorUuid: file.connectorUuid,
                    subscriber: file.subscriber,
                    label: file.label,
                    subConnector: file.subConnector,
                    storeStatus: file.storeStatus,
                })
            }
            setSelectedFiles(selection)
        } else {
            setSelectedFiles([])
        }
    }

    const confirmDelete = async () => {
        const toRefresh: { type: 'space' | 'directory'; id: string }[] = []

        for (const selectedFile of selectedFiles) {
            if (selectedFile.isDirectory) {
                const directory = files.recursivelyFindDirectory(selectedFile.uuid)
                toRefresh.push({ type: 'directory', id: directory.parent })
            } else if (selectedFile.isSpace) {
                const space = files.recursivelyFindSpace(selectedFile.uuid)
                toRefresh.push({ type: 'space', id: space.parent })
            } else if (selectedFile.isFile) {
                const file = files.recursivelyFindFile(selectedFile.uuid)
                toRefresh.push({ type: 'directory', id: file.directoryUuid })
            }
        }

        await files.delete(selectedFiles)
        setSelectedFiles([])
        for (const ref of toRefresh) {
            const directory = ref.type === 'directory' ? files.recursivelyFindDirectory(ref.id) : undefined
            const space = ref.type === 'space' ? files.recursivelyFindSpace(ref.id) : undefined
            await refresh(space, directory)
        }
    }

    const setChooseDirectory = async (
        newSpace: Space | undefined,
        newDirectory: SpaceDirectory | undefined,
        action: string,
        type: 'move' | 'copy'
    ) => {
        if (!id) {
            return
        }
        const isOnlySpaces = selectedFiles.filter(c => !c.isSpace).length === 0
        if (!isOnlySpaces && !newSpace) {
            return
        }

        setShowMoveTo(false)
        setShowCopyTo(false)
        setFileExists(false)

        setLoading(true)
        const toBeMoved = selectedFiles
        try {
            await files.moveFiles(toBeMoved, newSpace, newDirectory, action, type)

            setSelectedFiles([])

            files.markForceRefresh(
                [
                    newDirectory
                        ? newDirectory?.isRootDirectory
                            ? newDirectory.spaceUuid
                            : newDirectory.uuid
                        : newSpace.uuid,
                    ...toBeMoved.map(d => d.parentUuid),
                ]
                    .filter(Boolean)
                    .filter((v, i, a) => a.indexOf(v) === i)
            )

            if (files.askForRule.length > 0) {
                setAskKeepRule(true)
            }
        } catch (error) {
            console.error(error)
            if (error.code === 400 && error.message === 'api_filename_already_exists') {
                setFileExists(true)
            }
        }
        setLoaded(true)
        setLoading(false)
    }

    const showRenameForFile = (file: SpaceFile) => {
        setSuggestion(file)
        setShowRenameModal(file)
    }

    const rename = async (
        filename: string,
        tags: SuggestionTag[] | null,
        separator: string | undefined = undefined,
        transform: string | undefined = undefined
    ) => {
        setShowRenameModal(undefined)
        const file = suggestion
        if (file) {
            await file.rename(filename, tags, separator, transform)
            await files.getFilenameSuggestions(file)
        }
    }

    const [editedDirectory, setEditedDirectory] = useState<SpaceDirectory>()
    const onClientEdit = async (directory: SpaceDirectory) => {
        setCurrentSpace(space)
        setCurrentDirectory(space.rootDirectory)
        setEditedDirectory(directory)
    }

    const openCreateDirectory = (forUserFolder: boolean | undefined = false) => {
        if (!space) {
            return
        }
        const recursivelyFindDirectory = (space: Space, directory: SpaceDirectory, uuid: string) => {
            if (space.uuid === uuid) {
                setCurrentSpace(space)
                setCurrentDirectory(space.rootDirectory)
                setCreateUserDirectory(forUserFolder)
                setShowCreateDirectoryModal(true)

                return true
            }

            if (directory.uuid === uuid) {
                setCurrentSpace(space)
                setCurrentDirectory(directory)
                setCreateUserDirectory(forUserFolder)
                setShowCreateDirectoryModal(true)

                return true
            }

            const children = values<Space>(space.spaces)
            for (const child of children) {
                if (recursivelyFindDirectory(child, child.rootDirectory, uuid)) {
                    return true
                }
            }
            const directories = values<Space>(directory.directories)
            for (const child of directories) {
                if (recursivelyFindDirectory(space, child, uuid)) {
                    return true
                }
            }

            return false
        }

        recursivelyFindDirectory(space, space.rootDirectory, selectedFiles[0]?.uuid || space.rootDirectory.uuid)
    }

    const refreshCurrentSpace = async () => {
        if (!currentSpace) {
            return
        }
        files.markForceRefresh(currentSpace.uuid)
    }

    const openCreateSpace = () => {
        if (!space) {
            return
        }
        const recursivelyFindSpace = (space: Space, uuid: string): boolean => {
            if (space.uuid === uuid) {
                setCurrentSpace(space)
                setShowCreateSpaceModal(true)

                return true
            }

            const children = values<Space>(space.spaces)
            for (const child of children) {
                if (recursivelyFindSpace(child, uuid)) {
                    return true
                }
            }

            return false
        }
        recursivelyFindSpace(space, selectedFiles[0]?.uuid || space.uuid)
    }

    const collaborationCopyTo = () => {
        setShowCopyTo(true)
    }

    const shouldMoveOrCopyTo = (setAction: (value: boolean) => void, checkConnectors = false) => {
        if (!checkConnectors) {
            setAction(true)

            return
        }

        const connectors = selectedFiles
            .filter(s => s.connector !== undefined && s.connector !== '')
            .map(s => s.connector)
            .filter((value, index, self) => self.indexOf(value) === index)

        if (connectors.length === 0) {
            // no connectors
            setAction(true)

            return
        }

        const withConnectors = selectedFiles
            .filter(s => s.connector !== undefined && s.connector !== '')
            .filter(s => s.subConnector)
            .map(s => `<strong>${s.displayName} (${s.connector})</strong>`)

        if (withConnectors.length === 0) {
            // no subconnectors
            setAction(true)

            return
        }

        setAskForSelectParent(withConnectors)
    }

    const shouldCopyTo = () => {
        shouldMoveOrCopyTo(setShowCopyTo)
    }

    const shouldMoveTo = () => {
        shouldMoveOrCopyTo(setShowMoveTo, true)
    }

    const selectParents = () => {
        selectedFiles
            .filter(s => s.connector !== undefined && s.connector !== '')
            .filter(s => s.subConnector)
            .map(s => {
                let directory: SpaceDirectory | undefined
                if (s.isFile) {
                    directory = files.recursivelyFindDirectory(s.parentUuid)
                } else {
                    directory = files.recursivelyFindDirectory(s.uuid)
                }
                if (!directory) {
                    return undefined
                }

                return directory.findConnectorParent()
            })
            .forEach(d => changeSelection(d, true))

        setAskForSelectParent([])
        setShowMoveTo(true)
    }

    const dontKeepRule = () => {
        setAskKeepRule(false)
        files.clearRules()
    }

    const keepRules = async () => {
        setAskKeepRule(false)
        await files.keepRules()
    }

    const askOcr = async () => {
        setShowAskOcr(false)
        const [selectedFile] = selectedFiles
        const file = files.recursivelyFindFile(selectedFile.uuid)
        if (!file) {
            return
        }
        const directory = files.recursivelyFindDirectory(file.directoryUuid)
        const space = files.recursivelyFindSpace(file.spaceUuid)
        await files.askOcr(file.uuid)
        refresh(space, directory)
    }

    useEffect(() => {
        refreshCurrentSpace()
    }, [highlightedFile, highlightedDirectory, highlightedSpace])

    const checkIfCanOcr = async () => {
        if (!selectedFiles || selectedFiles.length !== 1) {
            setCanOcr(false)

            return
        }

        const file = files.recursivelyFindFile(selectedFiles[0].uuid)
        if (file && file.ocr.jobId !== '' && ['pending', 'running', 'finished'].includes(file.ocr.status)) {
            setCanOcr(false)

            return
        }

        const canOcr = await files.canOcr(selectedFiles[0].displayName)
        setCanOcr(canOcr)
    }

    const checkIfCanRefresh = (selectedFiles: DetachedSpaceOrDirectoryOrFile[]) => {
        if (selectedFiles.length === 0) {
            setCanRefresh(false)

            return
        }

        // https://stackoverflow.com/a/38206980
        const toRefresh = [...new Set(selectedFiles.map(({ canRefresh }) => canRefresh ?? false))]
        if (toRefresh.length > 1) {
            setCanRefresh(false)

            return
        }

        const [can] = toRefresh
        setCanRefresh(can)
    }

    const refreshDirectories = async () => {
        if (selectedFiles.length === 0) {
            return
        }

        if (confirm(t('web_refresh_selected_clients'))) {
            const id = reactToast.loading(t('web_refreshing_clients'))
            setIsRefreshing(true)

            const directories = selectedFiles.map(({ uuid }) => uuid)

            const { success } = await post<{ directories: typeof directories }, { success: boolean }>(
                '/v1/web/directories/refresh',
                {
                    directories,
                }
            )

            setIsRefreshing(false)
            reactToast.update(id, {
                render: t(success ? 'web_refresh_done' : 'web_refresh_error'),
                type: success ? 'success' : 'error',
                isLoading: false,
                autoClose: 2500,
            })

            if (success) {
                setSelectedFiles([])
            }
        }
    }

    const onOpenChecklist = async (directory: SpaceDirectory) => {
        const client = await files.getChecklist(directory.uuid)
        setClient(client)
        setCurrentDirectory(directory)
    }

    const onCompletionChange = (completion: number) => {
        currentDirectory?.setChecklistPercentage(completion)
    }

    const fetchUserSpaces = async (uuid: string) => {
        const {
            data: { spaces },
        } = await get<never, { data: { spaces: GuestSpace[] } }>(
            `/v1/web/franchises/${user.currentFranchise.uuid}/users/${uuid}/spaces`
        )

        setNewUserAvailableDestinations(spaces)
    }

    const sendBills = async () => {
        const postData = selectedFiles
            .filter(f => f.isFile)
            .map(f => files.recursivelyFindFile(f.uuid))
            .filter(Boolean)
            .map(
                f => {
                    if (!f.rhUser) {
                        return undefined
                    }

                    return { file: f.uuid, user: f.rhUser }
                },
                [] as { file: string; user: string }[]
            )
            .filter(Boolean)

        await post<{ files: typeof postData }, { data: { space: Space } }>(
            `/v1/web/brands/${user.currentBrand?.uuid}/bills`,
            { files: postData }
        )
        setSendBill(false)
        toast('success', 'web_bill_spent')
        files.markForceRefresh(space.uuid)
    }

    const changeNewUser = (newUser: { label: string; value: string }) => {
        setNewUser(newUser)
        setNewUserAvailableDestinations(undefined)
        setNewUserDestination(undefined)
        fetchUserSpaces(newUser.value)
    }

    const transferFiles = async () => {
        if (!selectedFiles || selectedFiles.length === 0 || !newUser || !newUserDestination) {
            return
        }

        setLoading(true)
        const space = await files.transferFiles(
            selectedFiles,
            newUserDestination.uuid,
            newUserDestination.type,
            newUser.value
        )
        setLoading(false)
        setShowTransferModal(false)
        toast('success', 'web_share_transfered')
        navigate(`/dataroom/spaces/${space}`)
    }

    const isSecureCollaboration = () => {
        if (!space.isShared) {
            return false
        }

        const config = space.jsonOptions
        const isSecure = !!(config?.secure_collaboration ?? false)

        return isSecure
    }

    const isFileSecureCollaboration = () => {
        if (!isSecureCollaboration()) {
            return false
        }

        return space.userUuid !== user.uuid
    }

    const isClientDirectory = () => {
        if (!space) {
            return false
        }
        if (selectedFiles.length === 0) {
            return (
                space.jsonOptions && typeof space.jsonOptions.createUser !== 'undefined' && space.jsonOptions.createUser
            )
        }

        if (selectedFiles.length !== 1) {
            return false
        }

        const [selectedFile] = selectedFiles
        if (!selectedFile || !selectedFile.isDirectory) {
            return false
        }

        const directory = files.recursivelyFindDirectory(selectedFile.uuid)

        return directory?.jsonOptions.createUser
    }

    const hasClientDirectory = () => {
        if (!space) {
            return false
        }
        if (selectedFiles.length === 0) {
            return (
                space.jsonOptions && typeof space.jsonOptions.createUser !== 'undefined' && space.jsonOptions.createUser
            )
        }

        return selectedFiles.some(f => {
            if (!f.isDirectory) {
                return false
            }

            const directory = files.recursivelyFindDirectory(f.uuid)

            return directory?.jsonOptions.createUser
        })
    }

    const operationsAllowedOnSelection = () => {
        return selectedFiles.every(f => {
            if (!f.isDirectory) {
                return true
            }

            const directory = files.recursivelyFindDirectory(f.uuid)

            return !directory?.jsonOptions.clientDirectory && !directory?.jsonOptions.checklistDirectory
        })
    }

    const [showPanel, setShowPanel] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<ActiveTab>()

    const toggleSpaceInformations = (tab?: ActiveTab) => {
        setActiveTab(tab)
        setShowPanel(show => !show)
    }

    const validateStore = async () => {
        const destinations: {
            refresh: {
                directory: string
                newDirectory: string
                space: string
            }
            destination: {
                space: Space | undefined
                directory: SpaceDirectory | undefined
            }
            files: SpaceFile[]
        }[] = []
        const onlyFiles = selectedFiles.filter(f => f.isFile)
        for (const detachedFile of onlyFiles) {
            const file = files.recursivelyFindFile(detachedFile.uuid)
            if (!file || !file.suggestion) {
                continue
            }

            if (file.suggestion.status === FileStatus.to_sort) {
                continue
            }

            if (file.suggestion.status === FileStatus.quarantine) {
                await file.suggestion.moveToQuarantine()

                continue
            }

            if (file.suggestion.status === FileStatus.dupe) {
                await files.delete([
                    {
                        displayName: file.displayName,
                        uuid: file.uuid,
                        isFile: true,
                        isDirectory: false,
                        isSpace: false,
                        isEditable: file.isEditableBy(user),
                        isDeletable: file.isDeletableBy(user),
                        parentUuid: file.parentUuid,
                    },
                ])

                continue
            }

            const directory = file.directoryUuid
            const space = file.spaceUuid

            if (file.suggestion.status === FileStatus.pre_sorted && file.suggestion.suggestedSpace) {
                const newDirectory = file.suggestion.suggestedDirectory?.uuid
                if (file.suggestion.suggestedDirectory) {
                    const destination = {
                        refresh: {
                            directory,
                            newDirectory,
                            space,
                        },
                        destination: {
                            space: file.suggestion.suggestedSpace,
                            directory: file.suggestion.suggestedDirectory,
                        },
                        files: [file],
                    }
                    destinations.push(destination)
                }
            } else {
                const newDirectory = file.suggestion.directory.uuid
                const suggestionSpace = file.suggestion.space
                const suggestionDirectory = file.suggestion.directory

                let destination = destinations.find(
                    d =>
                        d.destination.space.uuid === suggestionSpace.uuid &&
                        d.destination.directory.uuid === suggestionDirectory.uuid &&
                        d.refresh.space === space &&
                        d.refresh.directory === directory &&
                        d.refresh.newDirectory === newDirectory
                )
                if (!destination) {
                    destination = {
                        refresh: {
                            directory,
                            newDirectory,
                            space,
                        },
                        destination: {
                            space: suggestionSpace,
                            directory: suggestionDirectory,
                        },
                        files: [],
                    }
                    destinations.push(destination)
                }

                destination.files.push(file)
            }
        }

        let fileMoved = 0
        for (const destination of destinations) {
            await files.moveFiles(
                destination.files,
                destination.destination.space,
                destination.destination.directory,
                'rename',
                'move',
                false
            )
            fileMoved += destination.files.length
        }

        toast('success', 'web_dataroom_file_saved', folder, undefined, {
            count: fileMoved,
        })

        setSelectedFiles([])

        files.markForceRefresh(
            destinations.flatMap(destination => [
                destination.refresh.space,
                destination.refresh.directory,
                destination.refresh.newDirectory,
            ])
        )
    }

    useEffect(() => {
        checkIfCanOcr()
        checkIfCanRefresh(selectedFiles)
    }, [selectedFiles])

    useEffect(() => {
        if (files.ocrFileMovedTo && space && space.isStack) {
            toast('success', 'web_dataroom_ocr_file_moved', undefined, undefined, { space: files.ocrFileMovedTo })

            setTimeout(() => {
                window.location.reload()
            }, 3000) // after toast closes (2500)

            files.setOcrFileMovedTo(undefined)
        }
    }, [files.ocrFileMovedTo])

    useEffect(() => {
        const onKeydown = (e: KeyboardEvent) => {
            if (!showPanel) {
                return
            }
            if (e.key !== 'Escape') {
                return
            }

            toggleSpaceInformations()
        }
        window.addEventListener('keydown', onKeydown)

        return () => {
            window.removeEventListener('keydown', onKeydown)
        }
    }, [showPanel])

    const [editTitleRequested, setEditTitleRequested] = useState<boolean>(false)

    const downloadFiles = async () => {
        if (selectedFiles.length === 1 && selectedFiles[0].isFile && selectedFiles[0].downloadUrl) {
            window.location.href = selectedFiles[0].downloadUrl

            return
        }

        const id = reactToast.loading(t('web_download_archive_config'))
        const { data } = await get<
            { files: string[]; directories: string[]; spaces: string[] },
            {
                data: {
                    region: string
                    accessKeyId: string
                    secretAccessKey: string
                    config: {
                        bucket: string
                        filenames: { key: string; filename: string }
                        destination: string
                        zipFileName: string
                    }
                }
            }
        >('/v1/web/files/download/config', {
            files: selectedFiles.filter(f => f.isFile).map(f => f.uuid),
            directories: selectedFiles.filter(f => f.isDirectory).map(f => f.uuid),
            spaces: selectedFiles.filter(f => f.isSpace).map(f => f.uuid),
        })
        const { region, accessKeyId, secretAccessKey, config } = data

        reactToast.update(id, { render: t('web_download_archive_loading') })
        const client = new LambdaClient({
            region,
            credentials: {
                accessKeyId,
                secretAccessKey,
            },
        })
        const command = new InvokeCommand({
            FunctionName: 'TreasyZip',
            Payload: JSON.stringify(config),
            LogType: LogType.Tail,
        })

        const { Payload } = await client.send(command)
        const decoded = new TextDecoder().decode(Payload)
        const { body } = JSON.parse(decoded)
        const { url } = JSON.parse(body)

        reactToast.update(id, {
            render: t('web_download_archive_done'),
            type: 'success',
            isLoading: false,
            autoClose: 2500,
        })
        window.location.href = url
    }

    return (
        <>
            {!isLoaded ? (
                <ContentPage>
                    <SmallLoader />
                </ContentPage>
            ) : (
                <>
                    {!space ? (
                        <NoMatch />
                    ) : (
                        <ContentPage
                            title={space.displayNameForUser(user)}
                            space={space}
                            canDrag={typeof showRenameModal === 'undefined'}
                        >
                            <div
                                className={cn(
                                    'z-40 fixed bottom-32 right-5 flex flex-col gap-3',
                                    showPanel ? 'hidden' : ''
                                )}
                            >
                                <button
                                    type="button"
                                    onClick={() => toggleSpaceInformations('details')}
                                    className={cn(
                                        'rounded-full bg-white flex items-center justify-center w-12 h-12 shadow-md'
                                    )}
                                >
                                    <InfoIcon />
                                </button>
                                {space.isShared && <ChatIconButton onClick={() => toggleSpaceInformations('chat')} />}
                                {space.isShared && space.collaborationManagers.includes(user.uuid) && (
                                    <TrashIconButton onClick={() => toggleSpaceInformations('trash')} />
                                )}
                            </div>

                            <Breadcrumb>
                                <Link to={space.isShared ? '/collaboration' : '/dataroom'}>
                                    {isSecureCollaboration() && (
                                        <Tippy content={t('web_collaboration_confidential')}>
                                            <FontAwesomeIcon icon={faUserSecret} className="mr-2" />
                                        </Tippy>
                                    )}
                                    {space.isShared ? t('web_link_collaboration') : t('web_breadcrumb_dataroom')}
                                </Link>
                                <div className="flex items-center justify-center space-x-2">
                                    <IconSpace
                                        className="w-5 flex-none fill-current"
                                        style={{ color: space.color }}
                                        isShared={space.isShared}
                                    />
                                    <span>{space.displayNameForUser(user)}</span>
                                </div>
                            </Breadcrumb>

                            <div className="relative flex h-full w-full gap-4">
                                <Panel>
                                    {isStack && space.files.length === 0 && space.directories.length === 0 ? (
                                        <div
                                            className="m-2 flex h-32 w-full flex-none cursor-pointer flex-col items-center p-2"
                                            onClick={() => trigger('uploader:trigger')}
                                        >
                                            <UploadImage />
                                            <h2 className="text-xl font-bold">{t('web_dataroom_upload_drag_drop')}</h2>
                                            <p className="text-sm">{t('web_dataroom_upload_add_files')}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {user.canManagePersonalData && (
                                                <div className="mb-4 flex w-full border-b pb-2">
                                                    <div className="relative">
                                                        <div
                                                            className="flex items-center space-x-2 rounded border border-gray-200 p-2 text-regent-gray"
                                                            onClick={() => setShowSpaceroomMenu(true)}
                                                        >
                                                            <CogIcon className="fill-current" />
                                                            <ArrowRight className="fill-current text-gray-200" />
                                                        </div>
                                                        <SpaceroomMenu
                                                            space={space}
                                                            isVisible={showSpaceroomMenu}
                                                            onClose={() => {
                                                                setShowSpaceroomMenu(false)
                                                                setEditTitleRequested(false)
                                                            }}
                                                            onRefresh={() => refreshCurrentSpace()}
                                                            onDeleted={() => navigate('/dataroom')}
                                                            isShared={space.isShared}
                                                            shareLocked={space.shareLocked}
                                                            mine={space.userUuid === user.uuid}
                                                            editTitleRequested={editTitleRequested}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <Grid
                                                columns={columns}
                                                onSelect={
                                                    user.canManagePersonalData
                                                        ? selectedAll => toggleAll(selectedAll)
                                                        : undefined
                                                }
                                            >
                                                {values<Space>(space.spaces).length === 0 &&
                                                    values<SpaceDirectory>(space.rootDirectory.directories).length ===
                                                        0 &&
                                                    values<SpaceFile>(space.rootDirectory.files).length === 0 && (
                                                        <Line>
                                                            <Row className="col-span-full text-center">
                                                                {t('web_no_files')}
                                                            </Row>
                                                        </Line>
                                                    )}
                                                {[...values<Space>(space.spaces)]
                                                    .sort((a: Space, b: Space) => {
                                                        return collator.compare(a.displayName, b.displayName)
                                                    })
                                                    .map(space => (
                                                        <TrSpace
                                                            isRefreshing={isRefreshing}
                                                            key={space.uuid}
                                                            space={space}
                                                            level={0}
                                                            cmp={cmp}
                                                            selected={
                                                                selectedFiles.find(f => f.uuid === space.uuid) !==
                                                                undefined
                                                            }
                                                            canChangeSelection={true}
                                                            onSelect={s => changeSelection(s, true)}
                                                            onDeselect={s => changeSelection(s, false)}
                                                            selectedFiles={selectedFiles}
                                                            highlightedFile={highlightedFile}
                                                            highlightedDirectory={highlightedDirectory}
                                                            highlightedSpace={highlightedSpace}
                                                            openingIds={uuids}
                                                            onRenameSuggestionClicked={showRenameForFile}
                                                            canOfferSuggestion={isStack}
                                                            onOpenChecklist={onOpenChecklist}
                                                        />
                                                    ))}
                                                {[...values<SpaceDirectory>(space.rootDirectory.directories)]
                                                    .sort((a: SpaceDirectory, b: SpaceDirectory) => {
                                                        return collator.compare(a.displayName, b.displayName)
                                                    })
                                                    .map(child => (
                                                        <Directory
                                                            isRefreshing={isRefreshing}
                                                            key={child.uuid}
                                                            directory={child}
                                                            space={space}
                                                            color={space.color}
                                                            level={0}
                                                            cmp={cmp}
                                                            canChangeSelection={true}
                                                            selected={
                                                                selectedFiles.find(f => f.uuid === child.uuid) !==
                                                                undefined
                                                            }
                                                            onSelect={s => changeSelection(s, true)}
                                                            onDeselect={s => changeSelection(s, false)}
                                                            selectedFiles={selectedFiles}
                                                            highlightedFile={highlightedFile}
                                                            highlightedDirectory={highlightedDirectory}
                                                            openingIds={uuids}
                                                            onRenameSuggestionClicked={showRenameForFile}
                                                            onSetFileDocumentTypeClicked={(file: SpaceFile) =>
                                                                setFileDocumentTypeModal([file])
                                                            }
                                                            canOfferSuggestion={isStack}
                                                            onOpenChecklist={onOpenChecklist}
                                                            onClientEdit={onClientEdit}
                                                        />
                                                    ))}
                                                {values<SpaceFile>(space.rootDirectory.files).map(child => (
                                                    <File
                                                        isRefreshing={isRefreshing}
                                                        key={child.uuid}
                                                        file={child}
                                                        space={space}
                                                        directory={space.rootDirectory}
                                                        level={0}
                                                        selected={
                                                            selectedFiles.find(f => f.uuid === child.uuid) !== undefined
                                                        }
                                                        canChangeSelection={true}
                                                        onSelect={s => changeSelection(s, true)}
                                                        onDeselect={s => changeSelection(s, false)}
                                                        highlightedFile={highlightedFile}
                                                        onRenameSuggestionClicked={showRenameForFile}
                                                        onSetFileDocumentTypeClicked={(file: SpaceFile) =>
                                                            setFileDocumentTypeModal([file])
                                                        }
                                                        canOfferSuggestion={isStack}
                                                    />
                                                ))}
                                            </Grid>
                                        </>
                                    )}
                                </Panel>

                                <SpaceInformationPanel
                                    show={showPanel}
                                    activeTab={activeTab}
                                    space={space}
                                    onClose={() => toggleSpaceInformations()}
                                    onEditTitle={() => setEditTitleRequested(true)}
                                />
                            </div>

                            <FloatingBar visible={user.canManagePersonalData}>
                                <div className="flex h-full flex-row">
                                    <div className="border-r border-christine px-6 py-2 text-3xl text-christine">
                                        {selectedFiles.length}
                                    </div>
                                    <div className="flex w-48 items-center px-6 text-xl font-bold leading-6">
                                        <Trans
                                            i18nKey="web_dataroom_selected_files"
                                            values={{ count: selectedFiles.length }}
                                        />
                                    </div>
                                    <div className="flex space-x-4 py-2 pr-4">
                                        {Config.app.VOUSFINANCER_V3_ENABLED && space.jsonOptions?.rh && canRefresh && (
                                            <FloatingBarButton
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={faRotateRight}
                                                        className="h-4 fill-current"
                                                    />
                                                }
                                                label="web_refresh"
                                                onClick={() => refreshDirectories()}
                                            />
                                        )}

                                        {selectedFiles.length > 0 &&
                                            !isFileSecureCollaboration() &&
                                            !(user.hasFranchise && hasClientDirectory()) &&
                                            (!space.isShared || (space.isShared && space.userUuid === user.uuid)) && (
                                                <FloatingBarButton
                                                    icon={
                                                        <FontAwesomeIcon icon={faCopy} className="h-4 fill-current" />
                                                    }
                                                    label="web_trash_copy_to"
                                                    onClick={() => shouldCopyTo()}
                                                />
                                            )}

                                        {selectedFiles.length > 0 &&
                                            !isFileSecureCollaboration() &&
                                            !(user.hasFranchise && hasClientDirectory()) &&
                                            !space.jsonOptions?.rh && (
                                                //(!space.isShared || (space.isShared && space.userUuid === user.uuid)) &&
                                                <FloatingBarButton
                                                    icon={<MoveTo className="h-4 fill-current" />}
                                                    label="web_trash_move_to"
                                                    onClick={() => shouldMoveTo()}
                                                />
                                            )}

                                        {Config.app.VOUSFINANCER_V3_ENABLED &&
                                            !space.jsonOptions?.rh &&
                                            selectedFiles.filter(
                                                file =>
                                                    file.isFile &&
                                                    !!file.object &&
                                                    (file.object as SpaceFile).documentTypeId.length === 0
                                            ).length > 0 && (
                                                <FloatingBarButton
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faShuffle}
                                                            className="h-4 fill-current"
                                                        />
                                                    }
                                                    label="web_checklist_document_type_set_button"
                                                    onClick={() =>
                                                        setFileDocumentTypeModal(
                                                            selectedFiles
                                                                .filter(
                                                                    file =>
                                                                        file.isFile &&
                                                                        !!file.object &&
                                                                        (file.object as SpaceFile).documentTypeId
                                                                            .length === 0
                                                                )
                                                                .map(file => file.object)
                                                        )
                                                    }
                                                />
                                            )}

                                        {selectedFiles.length > 0 && !isFileSecureCollaboration() && (
                                            <FloatingBarButton
                                                icon={
                                                    <FontAwesomeIcon icon={faDownload} className="h-4 fill-current" />
                                                }
                                                label="web_download_archive_file"
                                                onClick={() => downloadFiles()}
                                            />
                                        )}

                                        {selectedFiles.length > 0 &&
                                            !isFileSecureCollaboration() &&
                                            !(user.hasFranchise && hasClientDirectory()) &&
                                            !space.jsonOptions?.rh &&
                                            space.isShared &&
                                            space.userUuid !== user.uuid && (
                                                <FloatingBarButton
                                                    icon={
                                                        <FontAwesomeIcon icon={faClone} className="h-4 fill-current" />
                                                    }
                                                    label="web_collaboration_copy_to"
                                                    onClick={() => collaborationCopyTo()}
                                                />
                                            )}
                                        {isStack &&
                                            selectedFiles.length > 0 &&
                                            selectedFiles.some(f => f.isFile && f.storeStatus) && (
                                                <FloatingBarButton
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faShelves}
                                                            className="h-4 w-4 flex-none fill-current"
                                                        />
                                                    }
                                                    label="web_space_store"
                                                    onClick={() => validateStore()}
                                                />
                                            )}
                                        {!space.jsonOptions?.rh &&
                                            (selectedFiles.length === 0 ||
                                                (selectedFiles.length === 1 && selectedFiles[0].isSpace)) && (
                                                <FloatingBarButton
                                                    icon={<IconSpace className="h-4 w-4 flex-none fill-current" />}
                                                    label="web_create_space"
                                                    onClick={() => openCreateSpace()}
                                                />
                                            )}

                                        {!space.jsonOptions?.rh &&
                                            (selectedFiles.length === 0 ||
                                                (selectedFiles.length === 1 &&
                                                    (selectedFiles[0].isSpace || selectedFiles[0].isDirectory))) && (
                                                <FloatingBarButton
                                                    icon={<IconDirectory className="h-4 w-4 flex-none fill-current" />}
                                                    label="web_create_directory"
                                                    onClick={() => openCreateDirectory()}
                                                />
                                            )}

                                        {!space.jsonOptions?.rh && isClientDirectory() && (
                                            <FloatingBarButton
                                                icon={<IconUserDirectory className="h-4 w-4 flex-none fill-current" />}
                                                label={
                                                    brandConfig?.client_label
                                                        ? 'web_create_user_directory_custom'
                                                        : 'web_create_user_directory'
                                                }
                                                tParameters={
                                                    brandConfig?.client_label
                                                        ? { client_label: brandConfig?.client_label as string }
                                                        : undefined
                                                }
                                                onClick={() => openCreateDirectory(true)}
                                            />
                                        )}

                                        {!space.jsonOptions?.rh &&
                                            !isFileSecureCollaboration() &&
                                            user.hasFranchise &&
                                            user.currentFranchise.role === 'FRANCHISEE' &&
                                            selectedFiles.length > 0 && (
                                                <FloatingBarButton
                                                    icon={<FontAwesomeIcon icon={faTransporter2} />}
                                                    label="web_transfer_user"
                                                    onClick={() => setShowTransferModal(true)}
                                                />
                                            )}

                                        {!space.jsonOptions?.rh &&
                                            selectedFiles.length === 1 &&
                                            selectedFiles[0].isFile &&
                                            !isFileSecureCollaboration() &&
                                            canOcr && (
                                                <FloatingBarButton
                                                    icon={<Ocr className="h-4 fill-current" />}
                                                    label="web_ask_ocr"
                                                    onClick={() => setShowAskOcr(true)}
                                                />
                                            )}

                                        {!space.jsonOptions?.rh &&
                                            selectedFiles.length > 0 &&
                                            !isFileSecureCollaboration() && (
                                                <FloatingBarButton
                                                    icon={<ShareIcon className="h-4 fill-current" />}
                                                    label="web_share_file"
                                                    onClick={() => setShowShareLink(true)}
                                                />
                                            )}

                                        {space.jsonOptions?.rh &&
                                            space.jsonOptions?.kind === 'rh' &&
                                            selectedFiles.length > 0 && (
                                                <FloatingBarButton
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faFileInvoice}
                                                            className="h-4 fill-current"
                                                        />
                                                    }
                                                    label="web_send_bill_file"
                                                    onClick={() => setSendBill(true)}
                                                />
                                            )}

                                        {selectedFiles.length > 0 &&
                                            !isFileSecureCollaboration() &&
                                            !selectedFiles.some(f => !f.isDeletable) &&
                                            (!space.isShared ||
                                                (space.isShared &&
                                                    (space.collaborationManagers ?? []).includes(user.uuid))) && (
                                                <FloatingBarButton
                                                    icon={<TrashIcon className="h-4 fill-current" />}
                                                    label="web_trash_delete"
                                                    onClick={() => setShowConfirmDelete(true)}
                                                />
                                            )}
                                    </div>
                                </div>
                            </FloatingBar>

                            {fileDocumentTypeModal && (
                                <SpaceFileDocumentTypeModal
                                    onClose={() => {
                                        setFileDocumentTypeModal(undefined)
                                    }}
                                    onSaved={async (mappedFiles: SpaceFileWithDocumentType[]) => {
                                        setFileDocumentTypeModal(undefined)
                                        toast('success', t('web_checklist_types_saved'))
                                        setSelectedFiles([])
                                        await refresh(space, space.rootDirectory)
                                    }}
                                    documentTypes={documentTypes}
                                    filesToMap={fileDocumentTypeModal}
                                />
                            )}

                            <Modal
                                isOpen={showShareLink}
                                onRequestClose={() => setShowShareLink(false)}
                                disableSize
                                className="w-full lg:w-3/4 2xl:w-1/2"
                                showColor={false}
                                padding={0}
                                overflowHidden={false}
                            >
                                <ShareModalContent
                                    onClose={() => setShowShareLink(false)}
                                    selectedFiles={selectedFiles}
                                />
                            </Modal>

                            <Modal isOpen={showTransferModal} onRequestClose={() => setShowTransferModal(false)}>
                                <h4 className="my-4 text-center text-xl">{t('web_share_transfer_title')}</h4>
                                <p className="italic">{t('web_share_transfer_description')}</p>
                                <div className="flex w-full flex-col">
                                    <h5 className="my-4 text-lg">{t('web_share_transfer_new_user')}</h5>
                                    <AsyncSelect
                                        styles={selectStyles}
                                        components={{ Input }}
                                        className="mb-4 w-full"
                                        noOptionsMessage={() => t('web_share_transfer_new_user_no_options')}
                                        value={newUser}
                                        placeholder={t('web_share_transfer_new_user_placeholder')}
                                        loadOptions={async (inputValue: string) => {
                                            const { data } = await get<
                                                { cursor: number; search?: string },
                                                { data: { users: { uuid: string; email: string; fullname: string }[] } }
                                            >(`/v1/web/franchises/${user.currentFranchise.uuid}/users`, {
                                                cursor: 0,
                                                search: inputValue,
                                            })

                                            return data.users.map(u => ({
                                                value: u.uuid,
                                                label:
                                                    u.uuid === user.uuid
                                                        ? t('web_share_name_you')
                                                        : `${u.fullname} (${u.email})`,
                                            }))
                                        }}
                                        onChange={user => changeNewUser(user)}
                                    />
                                    <h5 className="my-4 text-lg">{t('web_share_transfer_destination')}</h5>
                                    <div className="flex w-full flex-col">
                                        {newUserAvailableDestinations?.map(space => (
                                            <DestinationSpace
                                                key={space.uuid}
                                                space={space}
                                                onSelect={setNewUserDestination}
                                            />
                                        ))}
                                    </div>
                                    <div className="my-2 flex w-full justify-center">
                                        <button
                                            type="button"
                                            className="btn"
                                            disabled={!newUser || !newUserDestination || loading}
                                            onClick={() => transferFiles()}
                                        >
                                            {t('web_share_transfer_button')}
                                        </button>
                                    </div>
                                </div>
                            </Modal>

                            <MoveFile
                                isVisible={showCopyTo}
                                showDirectories={true}
                                onClose={() => setShowCopyTo(false)}
                                onChoose={(space, directory, action) =>
                                    setChooseDirectory(space, directory, action, 'copy')
                                }
                                isCopy
                                selection={selectedFiles}
                            />

                            <MoveFile
                                isVisible={showMoveTo}
                                showDirectories={true}
                                onClose={() => setShowMoveTo(false)}
                                onChoose={(space, directory, action) =>
                                    setChooseDirectory(space, directory, action, 'move')
                                }
                                selection={selectedFiles}
                                fileExists={fileExists}
                            />

                            <Modal
                                isOpen={askForSelectParent.length > 0}
                                onCancel={() => setAskForSelectParent([])}
                                onRequestClose={() => setAskForSelectParent([])}
                                cancelLabel={t('web_connected_files_move_cancel')}
                                okLabel={t('web_connected_files_move_confirm')}
                                onConfirm={() => selectParents()}
                            >
                                <h4 className="my-4 text-center text-xl">{t('web_connected_files_move_title')}</h4>
                                <span className="text-sm text-regent-gray">
                                    <Trans
                                        i18nKey="web_connected_files_move_desc"
                                        values={{
                                            count: askForSelectParent.length,
                                            name: oxbridge(askForSelectParent, ', ', t('web_and_glue')),
                                        }}
                                    >
                                        web_connected_files_move_desc
                                    </Trans>
                                </span>
                            </Modal>

                            <Modal
                                size="1/4"
                                isOpen={showSendBill}
                                onCancel={() => setSendBill(false)}
                                onRequestClose={() => setSendBill(false)}
                                cancelLabel={t('web_show_ask_send_bill_cancel')}
                                okLabel={t('web_show_ask_send_bill_ok')}
                                onConfirm={() => sendBills()}
                            >
                                <SendBill className="w-80 text-christine" />
                                <h4 className="my-4 text-center text-xl">
                                    {t('web_show_ask_send_bill_title', { count: selectedFiles.length })}
                                </h4>
                            </Modal>

                            <Modal
                                size="1/4"
                                isOpen={showAskOcr}
                                onCancel={() => setShowAskOcr(false)}
                                onRequestClose={() => setShowAskOcr(false)}
                                cancelLabel={t('web_show_ask_ocr_cancel')}
                                okLabel={t('web_show_ask_ocr_ok')}
                                onConfirm={() => askOcr()}
                            >
                                <Ocr className="w-20 text-christine" />
                                <h4 className="my-4 text-center text-xl">{t('web_show_ask_ocr_title')}</h4>
                            </Modal>

                            <Modal
                                size="1/4"
                                isOpen={askKeepRule}
                                onCancel={() => dontKeepRule()}
                                onRequestClose={() => dontKeepRule()}
                                cancelLabel={t('web_rule_create_rule_cancel')}
                                okLabel={t('web_rule_create_rule_ok')}
                                onConfirm={() => keepRules()}
                            >
                                <WomanSortedFile />
                                <h4 className="my-4 text-center text-xl">{t('web_rule_create_rule_title')}</h4>
                                <span className="text-sm text-regent-gray">{t('web_rule_create_rule_title')}</span>
                            </Modal>

                            <Modal
                                isOpen={showConfirmDelete}
                                size="1/4"
                                onCancel={() => setShowConfirmDelete(false)}
                                onRequestClose={() => setShowConfirmDelete(false)}
                                cancelLabel={t('web_dataroom_trash_number_files_cancel')}
                                okLabel={t('web_dataroom_trash_number_files_confirm')}
                                onConfirm={() => {
                                    setShowConfirmDelete(false)
                                    confirmDelete()
                                }}
                            >
                                <WomanTrash />
                                <h4 className="my-4 text-center text-xl">
                                    <Trans
                                        i18nKey="web_dataroom_trash_number_files"
                                        values={{ count: selectedFiles.length }}
                                    >
                                        web_dataroom_trash_number_files
                                    </Trans>
                                </h4>
                                <span className="text-sm text-regent-gray">
                                    {t('web_dataroom_trash_number_files_desc', { count: selectedFiles.length })}
                                </span>
                            </Modal>

                            <CreateSpace
                                isOpen={showCreateSpaceModal}
                                parent={currentSpace}
                                onClose={() => setShowCreateSpaceModal(false)}
                                onSpaceCreated={() => refreshCurrentSpace()}
                            />

                            <Modal isOpen={loading} onRequestClose={() => {}}>
                                <SmallLoader className="mt-6" />
                            </Modal>

                            <DirectoryModal
                                brandConfig={brandConfig}
                                isOpen={showCreateDirectoryModal}
                                onClose={() => setShowCreateDirectoryModal(false)}
                                onDirectoryCreated={(space, directory) => refresh(space, directory)}
                                space={currentSpace}
                                parent={currentDirectory ?? currentSpace?.rootDirectory}
                                userDirectory={createUserDirectory}
                                clientCustomFields={clientCustomFields}
                            />

                            <DirectoryModal
                                brandConfig={brandConfig}
                                isOpen={!!editedDirectory}
                                onClose={() => setEditedDirectory(undefined)}
                                onDirectoryUpdated={(space, directory) => refresh(space, directory)}
                                space={currentSpace}
                                parent={currentDirectory ?? currentSpace?.rootDirectory}
                                clientCustomFields={clientCustomFields}
                                userDirectory={(editedDirectory?.jsonOptions.userDirectory as boolean) ?? false}
                                editedDirectory={editedDirectory}
                                updateContext={true}
                            />

                            {client && (
                                <Checklist
                                    client={client}
                                    isOpen={client !== undefined}
                                    onClose={() => setClient(undefined)}
                                    onCompletionChange={onCompletionChange}
                                />
                            )}

                            {suggestion?.suggestion && (
                                <RenameModal
                                    isOpen={typeof showRenameModal !== 'undefined'}
                                    file={showRenameModal}
                                    onValidate={rename}
                                    onRequestClose={() => setShowRenameModal(undefined)}
                                />
                            )}
                        </ContentPage>
                    )}
                </>
            )}
        </>
    )
})

export default Spaceroom
