import { del, get, post, put } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'
import { _Pricing, Pricing } from './pricing'
import { RootInstance } from './store'
import { _SsoUser, _User, User } from './users'

export const _SmallPartner = types.model('SmallPartner', {
    uuid: '',
    enabledAt: types.optional(types.string, '', [null, undefined]),
    name: '',
    companyNumber: '',
    logo: '',
    icon: '',
    url: types.optional(types.string, '', [null, undefined]),
    contactUrl: '',
    healthPartner: false,
    isManager: false,
})
export interface SmallPartner extends Instance<typeof _SmallPartner> {}

export const _PartnerPricing = types.model('PartnerPricing', {
    isDefault: false,
    pricing: types.optional(_Pricing, {}, [null, undefined]),
})

export const _Partner = types
    .model('Partner', {
        uuid: '',
        enabledAt: types.optional(types.string, '', [null, undefined]),
        name: '',
        companyNumber: '',
        logo: '',
        icon: '',
        url: types.optional(types.string, '', [null, undefined]),
        contactUrl: '',
        healthPartner: false,
        pricings: types.array(_PartnerPricing),
        managers: types.array(types.late(() => _User)),
        caseManagers: types.array(types.late(() => _User)),
    })
    .views(self => ({
        get enabled(): boolean {
            return self.enabledAt !== ''
        },

        get enabledDate(): DateTime | undefined {
            return self.enabledAt !== '' ? DateTime.fromISO(self.enabledAt) : undefined
        },

        get pricing(): undefined | Pricing {
            return self.pricings.find(p => p.isDefault)?.pricing
        },

        get otherPricing(): Pricing[] {
            return self.pricings.filter(p => !p.isDefault).map(p => p.pricing)
        },
    }))
    .actions(self => ({
        reset: () => {
            applySnapshot(self, {})
        },

        refresh: partner => {
            applySnapshot(self, partner)
        },
    }))
    .actions(self => ({
        toggle: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield put<void, { data: typeof self }>(`/v1/bo/partners/${self.uuid}/toggle`)
                const {
                    data: { partner },
                } = data
                self.refresh(partner)
            } catch (error) {
                root.error.prepare(error)
            }
        }),
    }))

export interface Partner extends Instance<typeof _Partner> {}

const _Invitation = types.model('Invitation', {
    uuid: '',
    email: '',
    confidential: false,
    manager: types.optional(
        types.late(() => _SsoUser),
        {},
        [null, undefined]
    ),
})
export interface Invitation extends Instance<typeof _Invitation> {}

export const _Partners = types
    .model('Partners', {
        partners: types.array(_Partner),
        users: types.array(types.late(() => _User)),
        editingUser: types.optional(
            types.late(() => _User),
            {}
        ),
        totalUsers: 0,
        invitations: types.array(_Invitation),
    })
    .actions(self => ({
        add(partner) {
            const index = self.partners.findIndex(p => p.uuid === partner.uuid)
            if (index > -1) {
                self.partners[index] = partner
            } else {
                self.partners.push(partner)
            }
        },
        assignUser({ user }) {
            self.editingUser = user
        },
        assignUsers({ users, totalUsers }) {
            self.users = users.map(u => ({
                ...u,
                id: u.uuid,
            }))
            self.totalUsers = totalUsers
        },
        assignInvitations({ invitations }) {
            self.invitations = invitations
        },
    }))
    .actions(self => ({
        loadPartner: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: Partner }>(`/v1/web/partners/${id}`)
                const {
                    data: { partner },
                } = data

                self.add(partner)
            } catch (error) {
                console.error(error)
            }
        }),

        loadPartnerByToken: flow(function* (token: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: { partner: Partner; validSessionUser: boolean } }>(
                    `/v1/web/partners/${token}/by-token`
                )
                const {
                    data: { partner, validSessionUser },
                } = data

                if (partner) {
                    self.add(partner)
                }

                return { partner, validSessionUser }
            } catch (error) {
                console.error(error)

                return null
            }
        }),

        loadInvitations: flow(function* (id: string, limit: number, offset: number, q?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = {
                    limit,
                    offset,
                    q,
                }

                const data = yield get<typeof getData, { data: typeof self }>(
                    `/v1/web/partners/${id}/invitations`,
                    getData
                )
                const {
                    data: { invitations },
                } = data
                self.assignInvitations({ invitations })
            } catch (error) {
                console.error(error)
            }
        }),

        removeUser: flow(function* (partnerId: string, uuid: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.users.clear()

            try {
                yield del(`/v1/web/partners/${partnerId}/users/${uuid}`)
            } catch (error) {
                console.error(error)
            }
        }),

        loadUsers: flow(function* (limit: number, offset: number, q?: string, partnerId?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.users.clear()

            const getData = {
                limit,
                offset,
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { users, count },
                } = yield get<typeof getData, { data: typeof self }>(`/v1/web/partners/${partnerId}/users`, getData)
                self.assignUsers({ users, totalUsers: count })
            } catch (error) {
                console.error(error)
            }
        }),

        loadUser: flow(function* (partnerId: string, id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: User }>(`/v1/web/partners/${partnerId}/users/${id}`)
                const {
                    data: { user },
                } = data

                self.assignUser({ user })
            } catch (error) {
                console.error(error)
            }
        }),

        updateUser: flow(function* (
            partnerId: string,
            id: string,
            firstname: string,
            email: string,
            secondaryEmail: string,
            phone: string,
            birthdate: Date,
            tags: string[],
            type: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                firstname,
                email,
                secondaryEmail,
                phone,
                birthdate: birthdate ? birthdate.toISOString() : null,
                tags,
                type,
            }

            try {
                const postData = yield put<typeof jsonData, void>(`/v1/web/partners/${partnerId}/users/${id}`, jsonData)
                const {
                    data: { user },
                } = postData
                self.assignUser({ user })

                toast('success', 'web_user_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        loadTags: flow(function* (id: string, q?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = {
                    q,
                }

                const data = yield get<typeof getData, { data: { tags: { uuid: string; name: string }[] } }>(
                    `/v1/web/partners/${id}/tags`,
                    getData
                )
                const {
                    data: { tags },
                } = data

                return tags
            } catch (error) {
                console.error(error)
            }
        }),

        createTag: flow(function* (id: string, name: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    name,
                }

                const data = yield post<typeof postData, { data: { tag: { uuid: string; name: string } } }>(
                    `/v1/web/partners/${id}/tags`,
                    postData
                )
                const {
                    data: { tag },
                } = data

                return tag
            } catch (error) {
                console.error(error)
            }
        }),
    }))

export interface Partners extends Instance<typeof _Partners> {}
