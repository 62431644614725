import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect } from 'react'
import { useMst } from '../../stores/store'
import InformationBar from '../shared/information-bar'

const InformationMessages: FC = observer(() => {
    const { informationMessageStore } = useMst()

    const onClick = useCallback(async informationMessageId => {
        await informationMessageStore.dismissMessage(informationMessageId)
        await informationMessageStore.load()
    }, [])

    useEffect(() => {
        informationMessageStore.load()
    }, [informationMessageStore.informationMessages])

    return (
        <>
            {informationMessageStore.informationMessages.map((informationMessage, index) => (
                <InformationBar
                    key={index}
                    color={informationMessage.color}
                    title={informationMessage.title}
                    message={informationMessage.bodyMessage}
                    link={informationMessage.link}
                    linkLabel={informationMessage.linkLabel}
                    buttonMessage={informationMessage.dismissible ? 'X' : null}
                    onClick={() => onClick(informationMessage.uuid)}
                />
            ))}
        </>
    )
})

export default InformationMessages
