import { DateTime } from 'luxon'
import { Instance, types } from 'mobx-state-tree'

export const _InformationMessage = types
    .model('information_message', {
        uuid: '',
        title: '',
        bodyMessage: '',
        link: types.optional(types.string, '', [null, undefined]),
        linkLabel: types.optional(types.string, '', [null, undefined]),
        color: '',
        dismissible: false,
        partnerUuid: types.optional(types.string, '', [null, undefined]),
        fromPartner: types.optional(types.string, '', [null, undefined]),
        startDate: types.optional(types.string, '', [null, undefined]),
        endDate: types.optional(types.string, '', [null, undefined]),
        createdAt: '',
        updatedAt: '',
        sendUsers: types.optional(types.array(types.string), [], [null, undefined]),
    })
    .views(self => ({
        get date(): DateTime {
            return DateTime.fromISO(self.createdAt)
        },

        get relativeDate(): string {
            return this.date.toRelative() ?? ''
        },

        get smartDate(): string {
            const { days } = DateTime.local().diff(this.date, 'days')
            if (days > 7) {
                return this.date.toLocaleString(DateTime.DATETIME_SHORT)
            }

            return this.relativeDate
        },
    }))

export interface InformationMessage extends Instance<typeof _InformationMessage> {}
