import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import validator from 'validator'
import hello from '../../assets/login/login-hello.svg'
import validated from '../../assets/login/validated.svg'
import { getFromQuery } from '../../core/use-query'
import { useMst } from '../../stores/store'
import { passwordRegex } from '../../stores/users'
import SecurePassword from '../shared/secure-password'
import TwoPart from './two-part'

const ResetPassword = () => {
    const { t } = useTranslation()
    const rootStore = useMst()
    const { user, error } = rootStore

    const emailToken = getFromQuery('token')

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false)
    const [logout, setLogout] = useState<boolean>(false)

    const canResetPassword =
        !isLoading &&
        [validator.matches(password, passwordRegex), password === confirmPassword].reduce(
            (previous, current) => previous && current,
            true
        )

    const resetPassword = async () => {
        setIsLoading(true)

        const { validSessionUser } = await user.changePassword(email, password, emailToken ?? user.emailToken)

        setIsLoading(false)
        if (error.hasError) {
            return
        }

        if (!validSessionUser) {
            setLogout(true)
        }

        setPasswordChanged(true)
    }

    return (
        <TwoPart
            image={hello}
            title={t('web_change_password_page_main_title')}
            subTitle={t('web_change_password_page_desc')}
        >
            {passwordChanged && (
                <>
                    <h3 className="my-8 text-2xl font-bold md:text-3xl">
                        {t('web_change_password_page_success_title')}
                    </h3>
                    <p className="my-4 text-center">{t('web_change_password_page_success')}</p>
                    <div className="pulse-button no-pulse mb-8" style={{ backgroundImage: `url(${validated})` }}></div>
                    <p className="text-center">
                        <Link to={`/dashboard${logout ? '?logout=1' : ''}`} className="btn px-8">
                            {t('web_forgot_password_page_continue')}
                        </Link>
                    </p>
                </>
            )}

            {!passwordChanged && (
                <>
                    <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_change_password_page_2_title')}</h3>
                    <p className="my-4 text-center">{t('web_change_password_page_2_help')}</p>
                    <div className="flex w-3/4 flex-col">
                        <label className="uppercase">{t('web_login_page_email_address')}</label>
                        <input
                            type="email"
                            className="mb-4"
                            placeholder={t('web_login_page_email_address')}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <label className="uppercase">{t('web_change_password_page_password')}</label>
                        <SecurePassword value={password} onChange={value => setPassword(value)} />
                        <p className="text-regent-gray mb-8 text-sm">{t('web_change_password_page_password_help')}</p>
                        <label className="uppercase">{t('web_change_password_page_confirm_password')}</label>
                        <SecurePassword value={confirmPassword} onChange={value => setConfirmPassword(value)} />

                        {error.hasError && <p className="text-christine my-4 text-center">{t(error.message)}</p>}

                        <p className="my-4 text-center">
                            <button className="btn px-8" disabled={!canResetPassword} onClick={() => resetPassword()}>
                                {t('web_change_password_page_continue')}
                            </button>
                        </p>
                        <p className="text-center">
                            <Link to="/login">{t('web_change_password_page_cancel')}</Link>
                        </p>
                    </div>
                </>
            )}
        </TwoPart>
    )
}

export default ResetPassword
